import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, message, Button, Avatar, Skeleton } from "antd";
import { getFollowees, getFollowers } from "../../../../../services/supabaseClient";
import { colors } from "../../../../../utils/service";
import useWindowSize from "../../../../../hooks/useWindowSize";
const PAGE_SIZE = 20;

const ListUsers = ({ type, total, onClose }) => {
  const { isMobileView } = useWindowSize();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(true);

  const fetchData = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime) setLoadingMore(true);
        else setLoading(true);

        let res;
        if (type === "FOLLOWERS") {
          res = await getFollowers(userId, startTime, PAGE_SIZE);
        } else {
          res = await getFollowees(userId, startTime, PAGE_SIZE);
        }

        if (res.error) {
          message.error(res.error.message);
        } else {
          if (res.data.length === 0) {
            if (startTime) setShowLoadmore(false);
            else setUsers([]);
            return;
          }

          if (startTime) {
            setUsers((prev) => [...prev, ...res.data]);
          } else {
            setUsers(res.data);
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [userId, type]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadMore = () => {
    const startTime = users.length > 0 ? users[users.length - 1].created_at : 0;

    fetchData(new Date(startTime).getTime());
  };

  const handleNavigate = (id) => {
    onClose();
    navigate(`/profile/${id}`);
  };

  return (
    <Flex vertical gap={18} style={{ height: isMobileView ? "70vh" : "60vh", overflow: "auto" }}>
      {loading &&
        [...new Array(9)].map((_e, i) => (
          <Flex key={i} gap={12} align="center">
            <Skeleton.Avatar active={true} size="large" shape="circle" />
            <Skeleton.Input active={true} block={true} />
          </Flex>
        ))}

      {!loading && users.length > 0 && (
        <>
          {users.map((item) => (
            <Flex
              onClick={() => handleNavigate(item.profile.id)}
              key={item.profile.id}
              align="center"
              gap={12}
              style={{ cursor: "pointer" }}>
              <Avatar size={48} src={item.profile.avatar} />
              <span style={{ fontWeight: "bold" }}>{item.profile.name}</span>
            </Flex>
          ))}

          {showLoadmore && total > users.length && (
            <Flex justify="center">
              <Button
                style={{ fontSize: "0.8rem", color: colors.primary }}
                size="small"
                shape="round"
                type="text"
                onClick={handleLoadMore}
                loading={loadingMore}>
                More
              </Button>
            </Flex>
          )}
        </>
      )}

      {!loading && users.length === 0 && <Flex justify="center">Follower list is empty!</Flex>}
    </Flex>
  );
};

export default ListUsers;
