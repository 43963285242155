import { useState, forwardRef } from "react";
import { Flex, Carousel } from "antd";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAuth } from "../../../contexts/AuthContext";
import { reactionActions } from "../../../utils/service";
import { follow, addPostReaction, removePostReaction } from "../../../services/supabaseClient";
import FeedImage from "./FeedImage/FeedImage";
import FeedContent from "./FeedContent/FeedContent";
import FeedActions from "./FeedActions/FeedActions";
import FeedRelatePerfumes from "./FeedRelatePerfumes/FeedRelatePerfumes";
import PerfumeAccords from "./FeedRelatePerfumes/PerfumeAccords/PerfumeAccords";
import FeedComment from "./FeedComment/FeedComment";
import FeedAudioActions from "./FeedAudioActions/FeedAudioActions";
import "./Feed.css";
const DELAY_TIME = 200;

const commentCountTypes = {
  increament: "increament",
  decreament: "decreament",
};

const Feed = forwardRef(
  (
    { post, index, onChangeReaction, onChangeFollow, playing, triggerPlaySound, muted, triggerMuteSound, ...prop },
    ref
  ) => {
    const { isMobileView } = useWindowSize();
    const { user, openLoginModal } = useAuth();

    // handle comments
    const [showComments, setShowComments] = useState(false);
    const [commentsAnimationState, setCommentsAnimationState] = useState("");
    const [feedAnimationState, setFeedAnimationState] = useState("");

    // handle relate perfumes
    const [showPerfumes, setShowPerfumes] = useState(false);
    const [perfumesAnimationState, setPerfumesAnimationState] = useState("");

    // handle perfume accords
    const firstRelatePerfume = post.perfumes ? post.perfumes[0] : null;
    const [perfume, setPerfume] = useState(firstRelatePerfume);
    const [showAccords, setShowAccords] = useState(firstRelatePerfume);
    const [accordsAnimationState, setAccordsAnimationState] = useState("");

    const onShowComments = () => {
      if (!isMobileView) {
        setCommentsAnimationState("");
        setFeedAnimationState("appear-comments");
      }

      setShowComments(true);
    };

    const onHideComments = () => {
      if (!isMobileView) {
        setCommentsAnimationState("hide");
        setFeedAnimationState("hide-comments");
        setTimeout(() => setShowComments(false), DELAY_TIME);
      } else {
        setShowComments(false);
      }
    };

    const onTriggerRelatePerfumes = () => {
      if (!showPerfumes) {
        setPerfumesAnimationState("");
        setShowPerfumes(true);
      } else {
        setPerfumesAnimationState("hide-perfumes");
        setTimeout(() => {
          setShowPerfumes(false);
          setPerfume(null);
        }, DELAY_TIME);

        if (perfume) {
          setAccordsAnimationState("hide-accords");
          setTimeout(() => {
            setPerfume(null);
          }, DELAY_TIME);
        }
      }
    };

    const onTriggerAccords = (newPerfume) => {
      if (!perfume || newPerfume.id !== perfume.id) {
        setPerfume(newPerfume);
        setAccordsAnimationState("");
        setShowAccords(true);
      }
    };

    const onFollow = () => {
      if (!user) {
        openLoginModal();
        return;
      }

      const payload = {
        followee_id: post.owner.id,
      };

      follow(payload);
      onChangeFollow(post.owner.id);
    };

    const handleReactions = (action, reactionType) => {
      if (!user) {
        openLoginModal();
        return;
      }

      if (action === reactionActions.add) {
        doAddReaction(reactionType);
      } else {
        doRemoveReaction(reactionType);
      }
    };

    const doAddReaction = (reactionType) => {
      addPostReaction(post.id, reactionType);
      onChangeReaction(post.id, index, reactionActions.add, reactionType);
    };

    const doRemoveReaction = (reactionType) => {
      removePostReaction(post.id, reactionType);
      onChangeReaction(post.id, index, reactionActions.remove, reactionType);
    };

    const [commentCount, setCommentCount] = useState(post.total_comments);
    const handleCommentCountChange = (type) => {
      if (type === commentCountTypes.increament) {
        setCommentCount((prev) => prev + 1);
      } else {
        setCommentCount((prev) => prev - 1);
      }
    };

    const WIDTH_CARD = isMobileView ? "100%" : "30%";
    const styleGroupFloatButton = {
      position: "absolute",
      right: isMobileView ? "12px" : showComments ? "12px" : "-54px",
      bottom: "12px",
    };

    const styleRelatePerfumeContainer = {
      right: isMobileView ? "64px" : showComments ? "72px" : "14px",
      bottom: "152px",
    };

    const cardPostStyle = {
      width: WIDTH_CARD,
      borderRadius: showComments ? "12px 0 0 12px" : "12px",
      borderRight: showComments ? 0 : "none",
    };
    const cardPostClass = `${showComments ? "left-box-shadow" : "normal-box-shadow"}`;

    return (
      <Flex {...prop} ref={ref} justify="center" className="vfra-feed-container" data-index={index}>
        <Flex
          vertical
          justify="space-between"
          className={`vfra-post-container ${feedAnimationState} ${cardPostClass}`}
          style={cardPostStyle}>
          {post.media_data && post.media_data.urls && post.media_data.urls.length > 1 && (
            <Carousel slidesToShow={1} slidesToScroll={1} dotPosition="bottom" arrows infinite={false}>
              {post.media_data.urls.map((item) => (
                <FeedImage key={item} src={item} />
              ))}
            </Carousel>
          )}

          {post.media_data && post.media_data.urls && post.media_data.urls.length === 1 && (
            <FeedImage src={post.media_data.urls[0]} />
          )}

          <FeedContent
            post={post}
            onFollow={onFollow}
            handleReactions={handleReactions}
            vertical
            className="vfra-post-infor-container"
          />

          <FeedActions
            post={post}
            index={index}
            onShowComments={onShowComments}
            commentCount={commentCount}
            showPerfumes={showPerfumes}
            onTriggerRelatePerfumes={onTriggerRelatePerfumes}
            handleReactions={handleReactions}
            playing={playing}
            triggerPlaySound={triggerPlaySound}
            style={styleGroupFloatButton}
          />

          {post.perfumes && (
            <FeedRelatePerfumes
              style={styleRelatePerfumeContainer}
              className={`vfra-relate-perfumes-container ${perfumesAnimationState} ${
                showPerfumes ? "show-perfumes" : "hide-perfumes hidden"
              }`}
              perfumes={post.perfumes}
              activePerfume={perfume}
              onTriggerAccords={onTriggerAccords}
            />
          )}

          {perfume && (
            <PerfumeAccords
              className={`vfra-accords-container ${accordsAnimationState} ${
                showAccords ? "show-accords" : "hide-accords hidden"
              }`}
              perfume={perfume}
            />
          )}

          {post.music_data && (
            <FeedAudioActions
              muted={muted}
              triggerMuteSound={triggerMuteSound}
              playing={playing}
              triggerPlaySound={triggerPlaySound}
            />
          )}
        </Flex>

        <FeedComment
          postId={post.id}
          showComments={showComments}
          commentCount={commentCount}
          commentsAnimationState={commentsAnimationState}
          handleCommentCountChange={handleCommentCountChange}
          onHideComments={onHideComments}
        />
      </Flex>
    );
  }
);

export default Feed;
