import "../Feed.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Avatar, Button, Typography, Tag, Row, Col } from "antd";
import { formatTimeshow } from "../../../../utils/service";
import { useAuth } from "../../../../contexts/AuthContext";
import useWindowSize from "../../../../hooks/useWindowSize";
const { Paragraph } = Typography;

const FeedContent = ({ post, onFollow, handleReactions, ...props }) => {
  const { user } = useAuth();
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const [expand, setExpand] = useState(false);
  const converted_created_at = formatTimeshow(post.created_at);

  const expandedStyle = {
    background: "linear-gradient(rgb(0 0 0 / 1%), rgb(0 0 0 / 100%))",
    paddingTop: "50px",
  };

  return (
    <Flex {...props} style={expand ? expandedStyle : ""}>
      <Flex align="center" gap={12}>
        <Avatar
          style={{ cursor: "pointer" }}
          size="large"
          src={post.owner.avatar}
          onClick={() => navigate(`/profile/${post.owner.id}`)}
        />

        <Flex vertical="vertical" gap={3}>
          <span
            className="one-line-ellipsis"
            style={{ fontWeight: "bold", fontSize: isMobileView ? "1.1rem" : "0.9rem" }}>
            {post.owner.name}
          </span>

          <span className="vfra-post-infor-time">{converted_created_at}</span>
        </Flex>

        {user && user.user_id !== post.owner.id && post.owner.followed && (
          <Tag bordered={false} className="vfra-following-tag">
            Following
          </Tag>
        )}

        {user && user.user_id !== post.owner.id && !post.owner.followed && (
          <Button className="vfra-following-tag" size="small" shape="round" onClick={onFollow}>
            Follow
          </Button>
        )}
      </Flex>

      <Row>
        <Col span={21}>
          <Paragraph
            style={{
              marginTop: isMobileView ? "6px" : "12px",
              fontSize: isMobileView ? "1.1rem" : "0.8rem",
              overflow: "auto",
              maxHeight: "70vh",
            }}
            ellipsis={{
              rows: 1,
              expandable: "collapsible",
              symbol: (expanded) => (expanded ? "less" : "more"),
              expanded: expand,
              onExpand: () => setExpand((prev) => !prev),
            }}>
            {post.caption}
          </Paragraph>
        </Col>
      </Row>
    </Flex>
  );
};

export default FeedContent;
