import { Modal, Divider, Flex } from "antd";
// import { FacebookFilled, GithubFilled, TwitterCircleFilled } from "@ant-design/icons";
import GoogleLoginButton from "./GoogleLoginButton";

const LoginModal = ({ visible, onCancel }) => {
  return (
    <Modal getContainer={false} centered title="Sign In" open={visible} onCancel={onCancel} footer={null}>
      <Divider style={{ color: "rgb(107 114 128)", fontSize: "14px" }}>Sign in with</Divider>
      <Flex vertical gap="middle" style={{ width: "100%" }}>
        <GoogleLoginButton />
        {/* <Button block icon={<FacebookFilled />}>
          Sign up with Facebook
        </Button>
        <Button block icon={<GithubFilled />}>
          Sign up with GitHub
        </Button>
        <Button block icon={<TwitterCircleFilled />}>
          Sign up with Twitter
        </Button> */}
      </Flex>
    </Modal>
  );
};

export default LoginModal;
