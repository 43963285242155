import "./FeedAudioActions.css";
import { playIcon, pauseIcon, volumeHeightIcon, volumeXmarkIcon } from "../../../../utils/svgIcons";
import CustomeSvgIcon from "../../../../components/CustomSvgIcon";

const FeedAudioActions = ({ playing, muted, triggerPlaySound, triggerMuteSound }) => {
  return (
    <div className="vfra-audio-actions-container">
      <CustomeSvgIcon
        height={16}
        width={20}
        style={{ fill: "#ffffff" }}
        icon={playing ? pauseIcon : playIcon}
        onClick={triggerPlaySound}
      />
      <CustomeSvgIcon
        height={16}
        width={35}
        style={{ fill: "#ffffff" }}
        icon={muted ? volumeXmarkIcon : volumeHeightIcon}
        onClick={triggerMuteSound}
      />
    </div>
  );
};

export default FeedAudioActions;
