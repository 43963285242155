import { FloatButton, Space, Tooltip, Dropdown } from "antd";
import {
  CommentOutlined,
  CloseOutlined,
  HeartOutlined,
  HeartFilled,
  DislikeFilled,
  DislikeOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import CustomeSvgIcon from "../../../../components/CustomSvgIcon";
import { bookmarkIconFilled, bookmarkIconOutline } from "../../../../utils/svgIcons";
import useWindowSize from "../../../../hooks/useWindowSize";
import { reactionActions, reactionTypes, colors } from "../../../../utils/service";
import AudioAvatar from "../../../../components/AudioPlayer/AudioAvatar";

const FeedActions = ({
  post,
  index,
  onShowComments,
  commentCount,
  showPerfumes,
  onTriggerRelatePerfumes,
  handleReactions,
  playing,
  triggerPlaySound,
  ...props
}) => {
  const { isMobileView } = useWindowSize();

  // float button
  const getOffset = (value) => {
    const length = value.toString().length;
    return length === 4 ? [3, 54] : length === 3 ? [0, 54] : length === 2 ? [-4, 54] : [-13, 54];
  };

  // setting
  const menuItems = [
    {
      label: "Dislike",
      key: "dislike",
      icon: post.active_for_user_dislike ? <DislikeFilled style={{ color: colors.primary }} /> : <DislikeOutlined />,
    },
  ];

  const onMenuClick = (key) => {
    if (key === "dislike") {
      handleReactions(
        post.active_for_user_dislike ? reactionActions.remove : reactionActions.add,
        reactionTypes.dislike
      );
    }
  };

  return (
    <FloatButton.Group {...props} shape="circle">
      <Space size={30} direction="vertical">
        <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Save this post</span>}>
          <FloatButton
            badge={{
              count: post.total_saves,
              overflowCount: 999,
              offset: getOffset(post.total_saves),
              size: "small",
            }}
            onClick={() =>
              handleReactions(
                post.active_for_user_save ? reactionActions.remove : reactionActions.add,
                reactionTypes.save
              )
            }
            icon={
              post.active_for_user_save ? (
                <CustomeSvgIcon style={{ fill: colors.primary }} width="22" height="18" icon={bookmarkIconFilled} />
              ) : (
                <CustomeSvgIcon width="22" height="18" icon={bookmarkIconOutline} />
              )
            }
          />
        </Tooltip>

        <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">I love this post</span>}>
          <FloatButton
            badge={{
              count: post.total_hearts,
              overflowCount: 999,
              offset: getOffset(post.total_hearts),
              size: "small",
            }}
            onClick={() =>
              handleReactions(
                post.active_for_user_heart ? reactionActions.remove : reactionActions.add,
                reactionTypes.heart
              )
            }
            icon={post.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}
          />
        </Tooltip>

        <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Comments</span>}>
          <FloatButton
            badge={{
              count: commentCount,
              overflowCount: 999,
              offset: getOffset(commentCount),
              size: "small",
            }}
            onClick={onShowComments}
            icon={<CommentOutlined />}
          />
        </Tooltip>

        <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Share</span>}>
          <FloatButton onClick={onShowComments} icon={<ShareAltOutlined />} />
        </Tooltip>

        {post.perfumes && (
          <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Related perfumers</span>}>
            <FloatButton
              badge={{
                count: post.perfumes.length,
                overflowCount: 999,
                offset: getOffset(post.perfumes.length),
                size: "small",
              }}
              onClick={onTriggerRelatePerfumes}
              icon={showPerfumes ? <CloseOutlined /> : <img alt="" height={25} src={post.perfumes[0].image_url} />}
            />
          </Tooltip>
        )}

        <Dropdown
          menu={{
            items: menuItems,
            onClick: ({ key, domEvent }) => {
              domEvent.stopPropagation();
              onMenuClick(key);
            },
          }}
          placement="topLeft"
          trigger={["click"]}>
          <FloatButton onClick={(e) => e.stopPropagation()} icon={<MoreOutlined />} />
        </Dropdown>

        {post.music_data && (
          <Tooltip placement="right" title={!isMobileView && <span className="vfra-tooltip">Sound</span>}>
            <FloatButton
              className="vfra-feed-sound-container"
              onClick={triggerPlaySound}
              icon={
                <AudioAvatar
                  trackId={post.music_data.track_id}
                  className={`${playing ? "spinning" : ""}`}
                  style={{ borderRadius: "50%" }}
                  alt=""
                  height={40}
                />
              }
            />
          </Tooltip>
        )}
      </Space>
    </FloatButton.Group>
  );
};

export default FeedActions;
