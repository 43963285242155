import "../../Feed.css";
import { useNavigate } from "react-router-dom";
import { Flex, Tag } from "antd";

const maxCountAccords = 4;

const PerfumeAccords = ({ perfume, ...props }) => {
  const navigate = useNavigate();
  return (
    <Flex {...props} vertical gap={12}>
      <Flex justify="center">
        <span className="vfra-title-accords-popover" onClick={() => navigate(`/product/${perfume.id}`)}>
          {perfume.name}
        </span>
      </Flex>

      <Flex gap={12} justify="center" className={perfume.accords.length > maxCountAccords ? "vfra-accords-list" : ""}>
        {perfume.accords.map((accord) => (
          <Tag
            key={accord.name}
            title={accord.name}
            className="one-line-ellipsis"
            style={{ fontSize: "0.6rem" }}
            color={accord.color_code}>
            {accord.name}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export default PerfumeAccords;
