import "./Perfumes.css";
import { useState, useCallback, useMemo } from "react";
import { Input, Flex, message, Spin } from "antd";
import _ from "lodash";
import PopularPerfumes from "./PopularPerfumes/PopularPerfumes";
import SearchPerfumes from "./SearchPerfumes/SearchPerfumes";
import { search_perfumes } from "../../services/meilisearchPerfumes";
import { localStorage_fields } from "../../utils/localstorage";

const Perfumes = () => {
  const {
    searchValue: initialSearchValue,
    page: initialPage,
    perfumes: initialPerfumes,
    totalPerfumes: initialTotalPerfumes,
    searchMode: initialSearchMode,
  } = getHistorySearchFromCache();

  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [page, setPage] = useState(initialPage);
  const [perfumes, setPerfumes] = useState(initialPerfumes);
  const [totalPerfumes, setTotalPerfumes] = useState(initialTotalPerfumes);
  const [searchMode, setSearchMode] = useState(initialSearchMode);
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    async (valueSearch, newPage = 1) => {
      try {
        setLoading(true);
        const searchResults = await search_perfumes(valueSearch, newPage);
        setSearchMode(true);
        setTotalPerfumes(searchResults.estimatedTotalHits);

        if (newPage > 1) {
          setPerfumes([...perfumes, ...searchResults.hits]);
          storeHistorySearch(valueSearch, newPage, searchResults.estimatedTotalHits, [
            ...perfumes,
            ...searchResults.hits,
          ]);
        } else {
          setPerfumes(searchResults.hits);
          storeHistorySearch(valueSearch, newPage, searchResults.estimatedTotalHits, searchResults.hits);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [perfumes]
  );

  const handleChange = (e) => {
    const { value } = e.target;
    if (!value) {
      setSearchMode(false);
      setPerfumes([]);
      localStorage.removeItem(localStorage_fields.perfumesHistorySearch);
    } else {
      setPage(1);
      debouncedSearch(value);
    }

    setSearchValue(value);
  };

  const debouncedSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch]);

  const handleLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    onSearch(searchValue, newPage);
  };

  function storeHistorySearch(searchValue, page, totalPerfumes, perfumes) {
    const history = {
      searchValue,
      page,
      totalPerfumes,
      perfumes,
      searchMode: true,
    };

    localStorage.setItem(localStorage_fields.perfumesHistorySearch, JSON.stringify(history));
  }

  function getHistorySearchFromCache() {
    const history = localStorage.getItem(localStorage_fields.perfumesHistorySearch);
    if (history) return JSON.parse(history);
    else {
      return {
        searchValue: null,
        page: 1,
        totalPerfumes: 0,
        perfumes: [],
        searchMode: false,
      };
    }
  }

  return (
    <>
      <Flex justify="center" align="center" className="vfra-perfumes-container">
        <Input
          allowClear
          value={searchValue}
          onChange={handleChange}
          size="large"
          placeholder="Search perfumes by name, description..."
          className="vfra-search"
          suffix={loading && <Spin />}
        />
      </Flex>

      {!searchMode && <PopularPerfumes />}

      {searchMode && perfumes.length === 0 && (
        <Flex justify="center" style={{ marginTop: "24px" }}>
          <p style={{ color: "rgb(100 100 100)" }}>No data found!</p>
        </Flex>
      )}

      {searchMode && perfumes.length > 0 && (
        <SearchPerfumes
          totalPerfumes={totalPerfumes}
          perfumes={perfumes}
          loading={loading}
          onLoadMore={handleLoadMore}
        />
      )}
    </>
  );
};

export default Perfumes;
