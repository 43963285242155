import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Flex, List, Button, message, Typography, Modal, Dropdown } from "antd";
import { LikeOutlined, HeartOutlined, MoreOutlined, LikeFilled, HeartFilled } from "@ant-design/icons";
import { deleteComment, addCommentReaction, deleteCommentReaction } from "../../../services/supabaseClient";
import { useAuth } from "../../../contexts/AuthContext";
import { HEART_REACTION, LIKE_REACTION, ADD_REACTION_ACTION, DELETE_REACTION_ACTION } from "../../../utils/service";
import useWindowSize from "../../../hooks/useWindowSize";

const { Paragraph } = Typography;
const ownerItems = [
  {
    label: "Delete",
    key: "delete",
  },
];

const Comment = forwardRef(({ item, onDeleteComment, onChangeCommentReaction }, ref) => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const { user, openLoginModal } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onMenuClick = (key) => {
    if (key === "delete") {
      showDeleteConfirm();
    }
  };

  const showDeleteConfirm = () => {
    if (isSubmitting) {
      return;
    }

    return Modal.confirm({
      title: "Confirm delete comment",
      content: "Delete your comment permanently?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        doDeleteComment();
      },
      getContainer: document.getElementById("root"),
    });
  };

  const doDeleteComment = async () => {
    try {
      setIsSubmitting(true);
      const response = await deleteComment(item.id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Comment deleted!");
        onDeleteComment(item.id);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCommentReaction = (action, commentId, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (isSubmitting) {
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(commentId, reactionType);
    } else {
      doRemoveCommentReaction(commentId, reactionType);
    }
  };

  const doAddCommentReaction = async (commentId, reactionType) => {
    const payload = {
      comment_id: commentId,
      reaction_type: reactionType,
    };

    try {
      setIsSubmitting(true);
      const { error } = await addCommentReaction(payload);
      if (error) {
        message.error(error.message);
      } else {
        onChangeCommentReaction(commentId, reactionType, ADD_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const doRemoveCommentReaction = async (commentId, reactionType) => {
    try {
      setIsSubmitting(true);
      const { error } = await deleteCommentReaction(reactionType, commentId);
      if (error) {
        message.error(error);
      } else {
        onChangeCommentReaction(commentId, reactionType, DELETE_REACTION_ACTION);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <List.Item
      ref={ref}
      actions={[
        <Button
          style={{ border: "none" }}
          onClick={() =>
            item.active_for_user_heart
              ? handleCommentReaction("REMOVE_REACTION", item.id, HEART_REACTION)
              : handleCommentReaction("ADD_REACTION", item.id, HEART_REACTION)
          }
          icon={item.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}>
          {item.hearts_count}
        </Button>,
        <Button
          style={{ border: "none" }}
          onClick={() =>
            item.active_for_user_like
              ? handleCommentReaction("REMOVE_REACTION", item.id, LIKE_REACTION)
              : handleCommentReaction("ADD_REACTION", item.id, LIKE_REACTION)
          }
          icon={item.active_for_user_like ? <LikeFilled style={{ color: "#1677ff" }} /> : <LikeOutlined />}>
          {item.likes_count}
        </Button>,
      ]}>
      <List.Item.Meta
        avatar={
          <Avatar
            style={{ cursor: "pointer" }}
            size="large"
            src={item.user_avatar}
            onClick={() => navigate(`/profile/${item.user_id}`)}
          />
        }
        title={
          <Flex justify="space-between" align="center">
            <Flex vertical="vertical">
              <span style={{ fontWeight: "700" }}>{item.user_name}</span>
              <span style={{ fontWeight: "normal", fontSize: "0.75rem", color: "rgba(113,128,150), 0.75)" }}>
                {item.created_at}
              </span>
            </Flex>
            {user && user.user_id === item.user_id && (
              <Dropdown
                menu={{
                  items: ownerItems,
                  onClick: ({ key, domEvent }) => {
                    domEvent.stopPropagation();
                    onMenuClick(key);
                  },
                }}
                placement="bottomLeft"
                trigger={["click"]}>
                <Button
                  loading={isSubmitting}
                  onClick={(e) => e.stopPropagation()}
                  shape="circle"
                  size={isMobileView ? "small" : "default"}
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            )}
          </Flex>
        }
      />
      <Paragraph ellipsis={{ rows: 5, expandable: "collapsible", symbol: (expanded) => (expanded ? "less" : "more") }}>
        {item.comment}
      </Paragraph>
    </List.Item>
  );
});

export default Comment;
