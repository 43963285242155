import React from "react";
import { Button, message } from "antd";
import { GoogleCircleFilled } from "@ant-design/icons";
import { supabase } from "../services/supabaseClient";
import { clearLocalStorageByUser } from "../utils/localstorage";
import { useAuth } from "../contexts/AuthContext";
import { AUTH_STATUS } from "../utils/service";

const GoogleLoginButton = () => {
  const { setAuthStatus } = useAuth();

  const signInWithGoogle = async () => {
    clearLocalStorageByUser();
    const currentUrl = window.location.href.split("#")[0];

    try {
      setAuthStatus(AUTH_STATUS.authenticating);
      await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: currentUrl,
        },
      });
    } catch (error) {
      message.error(error.message);
      setAuthStatus(AUTH_STATUS.unauthenticate);
    }
  };

  return (
    <Button onClick={() => signInWithGoogle()} block icon={<GoogleCircleFilled />}>
      Sign up with Google
    </Button>
  );
};

export default GoogleLoginButton;
