import React, { useState, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Flex, Button, message, Typography, Space, Modal, Dropdown } from "antd";
import { LikeOutlined, HeartOutlined, MoreOutlined, LikeFilled, HeartFilled } from "@ant-design/icons";
import {
  deleteExplorerComment,
  addExplorerCommentReaction,
  removeExplorerCommentReaction,
} from "../../../../../../services/supabaseClient";
import { useAuth } from "../../../../../../contexts/AuthContext";
import {
  HEART_REACTION,
  LIKE_REACTION,
  ADD_REACTION_ACTION,
  DELETE_REACTION_ACTION,
} from "../../../../../../utils/service";

const primary_color = "#1677ff";
const { Paragraph } = Typography;
const ownerItems = [
  {
    label: "Delete",
    key: "delete",
  },
];

const CommentItem = forwardRef(({ item, onDeleteComment, onChangeCommentReaction }, ref) => {
  const navigate = useNavigate();
  const { user, openLoginModal } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onMenuClick = (key) => {
    if (key === "delete") {
      showDeleteConfirm();
    }
  };

  const showDeleteConfirm = () => {
    if (isSubmitting) {
      return;
    }

    return Modal.confirm({
      title: "Confirm delete comment",
      content: "Delete your comment permanently?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        doDeleteComment();
      },
      getContainer: document.getElementById("root"),
    });
  };

  const doDeleteComment = async () => {
    try {
      setIsSubmitting(true);
      const response = await deleteExplorerComment(item.id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Comment deleted!");
        onDeleteComment(item.id);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCommentReaction = (action, commentId, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(commentId, reactionType);
    } else {
      doRemoveCommentReaction(commentId, reactionType);
    }
  };

  const doAddCommentReaction = async (commentId, reactionType) => {
    const payload = {
      comment_id: commentId,
      reaction_type: reactionType,
    };

    try {
      addExplorerCommentReaction(payload);
      onChangeCommentReaction(commentId, reactionType, ADD_REACTION_ACTION);
    } catch (error) {
      message.error(error.message);
    }
  };

  const doRemoveCommentReaction = async (commentId, reactionType) => {
    try {
      removeExplorerCommentReaction(reactionType, commentId);
      onChangeCommentReaction(commentId, reactionType, DELETE_REACTION_ACTION);
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <>
      <Flex ref={ref} gap={12} style={{ padding: "6px 0" }}>
        <div>
          <Avatar
            style={{ cursor: "pointer" }}
            size="large"
            src={item.user_avatar}
            onClick={() => navigate(`/profile/${item.user_id}`)}
          />
        </div>

        <Flex vertical style={{ flexGrow: 1 }}>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={12}>
              <span style={{ fontWeight: "700", fontSize: "0.9rem" }}>{item.user_name}</span>
              <span style={{ fontWeight: "normal", fontSize: "0.7rem", color: "#606060", marginBottom: "-2px" }}>
                {item.created_at}
              </span>
            </Flex>

            {user && user.user_id === item.user_id && (
              <Dropdown
                menu={{
                  items: ownerItems,
                  onClick: ({ key, domEvent }) => {
                    domEvent.stopPropagation();
                    onMenuClick(key);
                  },
                }}
                placement="bottomLeft"
                trigger={["click"]}>
                <Button
                  loading={isSubmitting}
                  onClick={(e) => e.stopPropagation()}
                  size="small"
                  shape="circle"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            )}
          </Flex>

          <Flex vertical>
            <Paragraph
              style={{ marginBottom: "3px", fontSize: "0.8rem" }}
              ellipsis={{ rows: 3, expandable: "collapsible", symbol: (expanded) => (expanded ? "less" : "more") }}>
              {item.comment}
            </Paragraph>

            <Space>
              <Button
                style={{ border: "none" }}
                onClick={() =>
                  item.active_for_user_heart
                    ? handleCommentReaction("REMOVE_REACTION", item.id, HEART_REACTION)
                    : handleCommentReaction("ADD_REACTION", item.id, HEART_REACTION)
                }
                icon={item.active_for_user_heart ? <HeartFilled style={{ color: "hotpink" }} /> : <HeartOutlined />}>
                {item.hearts_count}
              </Button>
              <Button
                style={{ border: "none" }}
                onClick={() =>
                  item.active_for_user_like
                    ? handleCommentReaction("REMOVE_REACTION", item.id, LIKE_REACTION)
                    : handleCommentReaction("ADD_REACTION", item.id, LIKE_REACTION)
                }
                icon={item.active_for_user_like ? <LikeFilled style={{ color: primary_color }} /> : <LikeOutlined />}>
                {item.likes_count}
              </Button>
            </Space>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
});

export default CommentItem;
