import { useEffect, useRef, useState } from "react";
import { Flex } from "antd";
import useWindowSize from "../../../../hooks/useWindowSize";

const imageContainerStyle = {
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const FeedImage = ({ src, ...props }) => {
  const { isMobileView } = useWindowSize();
  const [hasBorderRadius, setHasBorderRadius] = useState(false);
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkImageSize = () => {
      if (imageRef.current && containerRef.current) {
        const img = imageRef.current;
        const container = containerRef.current;

        if (img.height < container.offsetHeight) {
          setHasBorderRadius(false);
        } else {
          setHasBorderRadius(true);
        }
      }
    };

    const tempImageRef = imageRef.current;
    if (tempImageRef) {
      tempImageRef.addEventListener("load", checkImageSize);
    }

    window.addEventListener("resize", checkImageSize);
    return () => {
      if (tempImageRef) {
        tempImageRef.removeEventListener("load", checkImageSize);
      }
      window.removeEventListener("resize", checkImageSize);
    };
  }, []);

  const imageStyle = { width: "100%", maxHeight: "100%", borderRadius: !isMobileView && hasBorderRadius ? "12px" : "" };
  const backgroundImageStyle = `linear-gradient(rgb(38, 38, 38, 0.9), rgb(29, 29, 29, 0.9)), url(${src})`;

  return (
    <Flex
      {...props}
      ref={containerRef}
      justify="center"
      align="center"
      style={{
        borderRadius: isMobileView ? "" : "12px",
        backgroundImage: backgroundImageStyle,
        ...imageContainerStyle,
      }}>
      <img ref={imageRef} alt="" style={imageStyle} src={src} />
    </Flex>
  );
};

export default FeedImage;
