import "../Feed.css";
import { Flex, Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useWindowSize from "../../../../hooks/useWindowSize";
import Comments from "./Comments/Comments";

const FeedComment = ({
  postId,
  showComments,
  commentCount,
  commentsAnimationState,
  handleCommentCountChange,
  onHideComments,
}) => {
  const { isMobileView } = useWindowSize();

  const WIDTH_CARD = isMobileView ? "100%" : "30%";
  const cardCommentsStyle = {
    width: WIDTH_CARD,
    borderRadius: showComments ? "0 12px 12px 0" : "12px",
    background: "#ffffff",
  };
  const cardCommentsClass = `${showComments ? commentsAnimationState || "show right-box-shadow" : "hidden"}`;

  return (
    <>
      {!isMobileView && (
        <div className={`vfra-comments-container ${cardCommentsClass}`} style={cardCommentsStyle}>
          <Flex justify="space-between" align="center">
            <span style={{ fontWeight: "bold", fontSize: "1rem", marginBottom: 0 }}>
              Comments
              {commentCount > 0 && (
                <span style={{ marginLeft: "6px", fontWeight: "normal", color: "rgb(96, 96, 96)" }}>
                  {commentCount}
                </span>
              )}
            </span>
            <Button
              style={{ marginRight: "-4px" }}
              onClick={onHideComments}
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </Flex>

          {showComments && <Comments postId={postId} onCommentCountChange={handleCommentCountChange} />}
        </div>
      )}

      {isMobileView && (
        <Drawer
          title={
            <Flex justify="space-between" align="center">
              <span style={{ fontWeight: "bold", fontSize: "1rem", marginBottom: 0 }}>
                Comments
                {commentCount > 0 && (
                  <span style={{ fontWeight: "normal", color: "rgb(96, 96, 96)", marginLeft: "6px" }}>
                    {commentCount}
                  </span>
                )}
              </span>
              <Button
                size={isMobileView ? "small" : "default"}
                onClick={onHideComments}
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
              />
            </Flex>
          }
          height="70%"
          placement="bottom"
          closable={false}
          onClose={onHideComments}
          open={showComments}
          getContainer={false}>
          <Comments postId={postId} onCommentCountChange={handleCommentCountChange} />
        </Drawer>
      )}
    </>
  );
};

export default FeedComment;
