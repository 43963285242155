import "../layouts.css";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import HeaderLayout from "../HeaderLayout/HeaderLayout";

const { Content } = Layout;

const NoneFooterLayout = () => {
  return (
    <Layout>
      <HeaderLayout />
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default NoneFooterLayout;
