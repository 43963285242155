import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, Skeleton, message } from "antd";
import { getPerfumeById } from "../../../../services/supabaseClient";
import useWindowSize from "../../../../hooks/useWindowSize";

const PerfumeInfo = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { isMobileView } = useWindowSize();

  const [perfume, setPerfume] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeById(productId);
      if (error) {
        message.error(error.message);
      } else {
        setPerfume(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const imgWidth = isMobileView ? "70px" : "150px";

  return (
    <>
      {loading && (
        <Skeleton
          active
          paragraph={{
            rows: isMobileView ? 3 : 5,
          }}
        />
      )}
      {!loading && (
        <Flex gap={12} align="center">
          <img alt="" width={imgWidth} src={perfume.image_url} />
          <Flex vertical gap={12}>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>{perfume.name}</span>
            <Flex gap={12} align="center" style={{ marginTop: "0.75rem", cursor: "pointer" }}>
              <span onClick={() => navigate(`/brand/${perfume.brand_id}`)}>{perfume.brand_name}</span>
              <img
                onClick={() => navigate(`/brand/${perfume.brand_id}`)}
                alt={perfume.brand_name}
                style={{ width: "3.125rem" }}
                src={perfume.brand_logo_url}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PerfumeInfo;
