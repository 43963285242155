import imageCompression from "browser-image-compression";
import { formatDistanceToNow } from "date-fns";

export const MOBILE_BREAKPOINT = 768;
export const PAGE_SIZE_NEW_FEED = 5;
export const TIME_RESET_NEW_FEED = 1200; // 20 minutes
export const MIN_TIME_SPENT = 1000; // 1 second

export const AUTH_STATUS = {
  authenticated: "authenticated",
  unauthenticate: "unauthenticate",
  authenticating: "authenticating",
};

export const colors = {
  primary: "#1677ff",
  hotpink: "hotpink",
};

export const formatNumber = (number) => number.toLocaleString("en-US");

export const formatISOToUTC7 = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  const utc7Date = new Date(date.getTime() + offset);

  const day = String(utc7Date.getUTCDate()).padStart(2, "0");
  const month = String(utc7Date.getUTCMonth() + 1).padStart(2, "0");
  const year = utc7Date.getUTCFullYear();
  const hours = String(utc7Date.getUTCHours()).padStart(2, "0");
  const minutes = String(utc7Date.getUTCMinutes()).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
};

export const formatISOToUTC7Date = (isoString) => {
  const date = new Date(isoString);
  const offset = 7 * 60 * 60 * 1000;
  return new Date(date.getTime() + offset);
};

export const countReactions = (data, type) => {
  const reaction = data.find((e) => e.reaction_type === type);
  return {
    reaction_count: reaction ? reaction.reaction_count : 0,
    active_for_user: reaction ? reaction.active_for_user : false,
  };
};

export const formatTimeshow = (dateString) => {
  return formatDistanceToNow(new Date(dateString), { addSuffix: true }).replace("about ", "");
};

export const convertComment = (data) => {
  const { reaction_count: likes_count, active_for_user: active_for_user_like } = countReactions(
    data.reactions_metadata,
    "like"
  );
  const { reaction_count: hearts_count, active_for_user: active_for_user_heart } = countReactions(
    data.reactions_metadata,
    "heart"
  );
  const created_at = formatTimeshow(data.created_at);

  return {
    id: data.id,
    user_id: data.user_id,
    comment: data.comment,
    likes_count,
    active_for_user_like,
    hearts_count,
    active_for_user_heart,
    replies_count: data.replies_count,
    user_name: data.user.name,
    user_avatar: data.user.avatar,
    created_at_original: data.created_at,
    created_at,
  };
};

export const classifyDataByName = (data, field_name) => {
  const classified = Array.from({ length: 26 }, (_, index) => {
    return {
      alphaCode: String.fromCharCode(index + 65),
      designers: [],
    };
  });

  data.forEach((item) => {
    const firstLetter = item[`${field_name}`].charAt(0).toUpperCase();

    // Calculate the index in the classified array (A=0, B=1, ..., Z=25)
    const index = firstLetter.charCodeAt(0) - 65;

    if (index >= 0 && index < 26) {
      classified[index].designers.push(item);
    }
  });

  return classified;
};

export const HEART_REACTION = "heart";
export const LIKE_REACTION = "like";
export const ADD_REACTION_ACTION = "add";
export const DELETE_REACTION_ACTION = "delete";

export const reactionActions = {
  add: "add",
  remove: "remove",
};
export const reactionTypes = {
  save: "save",
  heart: "heart",
  dislike: "dislike",
};

export const prevLoadImage = (data, field_name) => {
  if (data.length > 0) {
    data.forEach((item) => {
      const img = new Image();
      img.src = item[field_name];
    });
  }
};

//============= Votes =================

export const getTotalFromObject = (dataObject) => {
  if (!dataObject) return 0;

  let total = 0;
  for (const key in dataObject) {
    total += dataObject[key];
  }

  return total;
};

export const getMaxValueFromObject = (dataObject) => {
  if (!dataObject) return 0;

  let max = 0;
  for (const key in dataObject) {
    if (dataObject[key] > max) max = dataObject[key];
  }

  return max;
};

//============= Accords =================
export const getMaxValueFromList = (list, fieldName) => {
  if (list.length === 0) return 0;

  let max = 0;
  list.forEach((e) => {
    if (e[fieldName] > max) max = e[fieldName];
  });
  return max;
};

//============= Upload helper function =================
export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const imageConverterToJpg = (file, maxSizeMB = 2) =>
  new Promise(async (resolve, reject) => {
    if (file.type === "image/jpeg" || file.type === "image/jpg") {
      resolve({
        data: file,
      });
    }

    const options = {
      maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/jpeg",
    };

    try {
      const compressedFile = await imageCompression(file, options);
      resolve({
        data: compressedFile,
      });
    } catch (error) {
      reject({
        error: error,
      });
    }
  });

export const public_image_url = "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public";

// Function to extract dominant color from an image
export const getDominantColor = (imgElement) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set canvas size to the size of the image
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;

  // Draw the image onto the canvas
  context.drawImage(imgElement, 0, 0);

  // Get image pixel data
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  // Variables to accumulate color data
  let r = 0,
    g = 0,
    b = 0;
  let pixelCount = 0;

  // Loop through the pixel data, step by 4 (RGBA format)
  for (let i = 0; i < data.length; i += 4) {
    r += data[i]; // Red
    g += data[i + 1]; // Green
    b += data[i + 2]; // Blue
    pixelCount++;
  }

  // Calculate average values
  r = Math.floor(r / pixelCount);
  g = Math.floor(g / pixelCount);
  b = Math.floor(b / pixelCount);

  // Return the dominant color as an RGB string
  return `rgb(${r}, ${g}, ${b})`;
};
