export const localStorage_fields = {
  user: "user",
  page: "page",
  posts: "posts",
  lastIndexPost: "last_index_post",
  lastPost: "last_post",
  groupBrands: "group_brands",
  perfumesHistorySearch: "perfumes_history_search",
};

export const clearLocalStorageByUser = () => {
  localStorage.removeItem(localStorage_fields.user);
  localStorage.removeItem(localStorage_fields.perfumesHistorySearch);
};

export const expires_in = {
  day: 86400,
  week: 604800,
};

export const setItemWithExpiry = (key, value, seconds) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + seconds * 1000,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const getItemWithoutExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  return itemStr ? JSON.parse(itemStr) : null;
};

export const getPageFromStore = () => {
  const itemStr = localStorage.getItem(localStorage_fields.page);
  return itemStr ? parseInt(itemStr, 10) : 1;
};
