import "./AllRelatePost.css";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Empty, Flex, message, Row, Col, Button, Skeleton } from "antd";
import { colors } from "../../../utils/service";
import { getPostByPerfume } from "../../../services/supabaseClient";
import useWindowSize from "../../../hooks/useWindowSize";
import PreviewPost from "../../../components/ListPost/PreviewPost/PreviewPost";
import PerfumeInfo from "./PerfumeInfo/PerfumeInfo";

export const AllRelatePost = () => {
  window.scrollTo(0, 0);
  const { isMobileView } = useWindowSize();
  const { productId } = useParams();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showLoadmore, setShowLoadmore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const pageSize = isMobileView ? 4 : 8;

  const fetchData = useCallback(
    async (startAfterPost = null) => {
      if (startAfterPost) setLoadingMore(true);
      try {
        const { error, data } = await getPostByPerfume(productId, pageSize, startAfterPost);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
          } else {
            if (startAfterPost) {
              setPosts((prev) => [...prev, ...data]);
            } else {
              setPosts(data);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startAfterPost) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [productId, pageSize]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadmore = () => {
    const startAfterPost = posts[posts.length - 1].post.id;
    fetchData(startAfterPost);
  };

  const rowGutter = isMobileView ? [6, 6] : [36, 36];
  const itemSpan = isMobileView ? 12 : 6;

  return (
    <Row gutter={rowGutter} className="vfra-all-relate-post-container vfra-container">
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <PerfumeInfo />
      </Col>
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <Flex style={{ fontWeight: "bold", marginBottom: "12px" }}>
          <span>Relate posts</span>
        </Flex>

        {loading && (
          <Row gutter={rowGutter} style={{ marginTop: "12px" }}>
            {[...new Array(8)].map((_e, i) => (
              <Col key={i} span={itemSpan} style={{ width: "100%", height: "40vh" }}>
                <Skeleton.Node active={true} style={{ width: "100%" }} />
              </Col>
            ))}
          </Row>
        )}

        {!loading && posts.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Flex vertical align="center">
                <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Relate Post empty</p>
              </Flex>
            }
          />
        )}

        {!loading && posts.length > 0 && (
          <Row gutter={rowGutter} justify="start" style={{ marginTop: "6px" }}>
            {posts.map((post) => (
              <Col span={itemSpan} key={post.post.id}>
                <PreviewPost post={post.post} showManageButton={true} />
              </Col>
            ))}
          </Row>
        )}

        {!loading && showLoadmore && (
          <Flex justify="center" style={{ marginBottom: "12px", marginTop: "18px" }}>
            <Button
              style={{ fontSize: "0.8rem", color: colors.primary }}
              size="small"
              shape="round"
              type="text"
              onClick={handleLoadmore}
              loading={loadingMore}>
              More
            </Button>
          </Flex>
        )}
      </Col>
    </Row>
  );
};

export default AllRelatePost;
