import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Image, Card, Flex, Skeleton, message, Row, Col } from "antd";
import { getPerfumeById, addVotes, getPerfumeRates, getOwnVotes } from "../../../services/supabaseClient";
import { useAuth } from "../../../contexts/AuthContext";
import useWindowSize from "../../../hooks/useWindowSize";
import Rating from "../../../components/Rating";
import Accords from "./Accords/Accords";
import RelatedPosts from "./RelatedPosts/RelatedPosts";
import Votes from "./Votes/Votes";
import Notes from "./Notes/Notes";

const { Meta } = Card;

const styleMetaTitle = {
  whiteSpace: "normal",
  textOverflow: "none",
};

const styleBrandName = { fontWeight: "700", marginRight: "1.5rem", cursor: "pointer" };

const ProductDetails = () => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { productId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [perfume, setPerfume] = useState(null);

  //voting
  const [dataVotes, setDataVotes] = useState(null);
  const [dataVotesByUser, setDataVotesByUser] = useState(null);

  const [rateData, setRateData] = useState(null);
  const [valueRate, setValueRate] = useState(null);

  const fetchDataPerfume = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeById(productId);
      if (error) {
        message.error(error.message);
      } else {
        setPerfume(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  const fetchDataVotes = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeRates(productId);
      if (error) {
        message.error(error.message);
      } else {
        setRateData(data[0]?.rate ?? null);
        setDataVotes(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [productId]);

  const fetchDataVotesByUser = useCallback(async () => {
    try {
      const { data, error } = await getOwnVotes(productId);
      if (error) {
        message.error(error.message);
      } else {
        setValueRate(data[0]?.rate ?? null);
        setDataVotesByUser(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    }
  }, [productId]);

  useEffect(() => {
    fetchDataPerfume();
    fetchDataVotes();

    if (user) fetchDataVotesByUser();
    else setDataVotesByUser(null);
  }, [user, fetchDataPerfume, fetchDataVotes, fetchDataVotesByUser]);

  const updateDataVote = (oldValueVote, newValueVote) => {
    let count = rateData?.count ?? 0;
    const oldTotal = count ? rateData.value * count : 0;
    let newAverageScore = 0;
    if (oldValueVote) {
      newAverageScore = (oldTotal - oldValueVote + newValueVote) / count;
    } else {
      count = count + 1;
      newAverageScore = (oldTotal + newValueVote) / count;
    }

    const newData = {
      count,
      value: newAverageScore,
    };

    setRateData(newData);
  };

  const onRate = async (newValue) => {
    if (!user) message.warning("Please sign in to rate!");
    else {
      try {
        const dataRate = {
          rate: newValue,
        };

        const { error } = await addVotes(productId, dataRate);

        if (error) {
          message.error(error.message);
        } else {
          message.success("Your rate successfully saved!");
          updateDataVote(valueRate, newValue);
          setValueRate(newValue);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const colSpan = !isMobileView ? 12 : 24;
  const paddingCard = !isMobileView ? "24px" : "12px";
  const valueRateUser = valueRate ? valueRate : rateData?.value ? rateData?.value : perfume?.rating ?? 0;

  return (
    <>
      {loading && (
        <Skeleton
          active
          paragraph={{
            rows: 20,
          }}
        />
      )}

      {!loading && !perfume && (
        <Flex align="center" justify="center">
          <p style={{ color: "rgb(100 100 100)", fontSize: "0.825rem" }}>Data not found!</p>
        </Flex>
      )}

      {!loading && perfume && (
        <>
          <Row gutter={[24, 24]}>
            <Col span={colSpan}>
              <Card
                bordered={false}
                styles={{ body: { paddingTop: "0px", padding: paddingCard } }}
                cover={<Image style={{ padding: "2.25rem 30% 2.25rem 30%" }} width="auto" src={perfume.image_url} />}>
                <Meta title={<h4 style={styleMetaTitle}>{perfume.name}</h4>}></Meta>
                <Flex vertical="vertical">
                  <Rating value={valueRateUser} badge={rateData?.value} disabled={false} onChange={onRate} />
                  <Flex align="center" style={{ marginTop: "0.75rem" }}>
                    <span onClick={() => navigate(`/brand/${perfume.brand_id}`)} style={styleBrandName}>
                      {perfume.brand_name}
                    </span>
                    <img alt={perfume.brand_name} style={{ width: "3.125rem" }} src={perfume.brand_logo_url} />
                  </Flex>
                  <Accords accords={perfume.accords} />
                </Flex>
              </Card>
            </Col>
            <Col span={colSpan}>
              <Flex vertical>
                <Votes dataVotes={dataVotes} dataVotesByUser={dataVotesByUser} />
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col
              offset={perfume.notes.length > 16 && perfume.notes.length < 20 ? 5 : 0}
              span={perfume.notes.length > 16 && perfume.notes.length < 20 ? 14 : 24}>
              <Notes notes={perfume.notes} />
            </Col>
          </Row>
          <RelatedPosts />
        </>
      )}
    </>
  );
};

export default ProductDetails;
