import "./RelatedPosts.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Divider, Carousel, message, Row, Col, Button, Flex } from "antd";
import { getPostByPerfume } from "../../../../services/supabaseClient";
import { colors } from "../../../../utils/service";
import useWindowSize from "../../../../hooks/useWindowSize";
import PreviewPost from "../../../../components/ListPost/PreviewPost/PreviewPost";

const PAGE_SIZE = 8;

const RelatedPosts = () => {
  const { isMobileView } = useWindowSize();
  const { productId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { error, data } = await getPostByPerfume(productId, PAGE_SIZE);

        if (error) {
          message.error(error.message);
        } else {
          setPosts(data);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  const span = isMobileView ? 2 : 6;

  let isLessMode = false;
  if (posts.length <= span) isLessMode = true;

  return (
    <>
      {!loading && posts.length > 0 && (
        <div className="vfra-relate-posts-container">
          <Divider style={{ fontWeight: "bold", marginTop: isMobileView ? "2rem" : "3rem" }}>Relate Posts</Divider>

          {!isLessMode && (
            <Carousel
              style={{ marginBottom: "2rem" }}
              dotPosition="bottom"
              arrows
              slidesToShow={span}
              slidesToScroll={span}>
              {posts.map((post) => (
                <PreviewPost key={post.post.id} post={post.post} />
              ))}
            </Carousel>
          )}

          {isLessMode && (
            <Row gutter={[12, 12]} justify="center">
              {posts.map((post) => (
                <Col key={post.post.id} span={isMobileView ? 12 : 4}>
                  <PreviewPost post={post.post} />
                </Col>
              ))}
            </Row>
          )}

          <Flex justify="center" style={{ marginTop: "18px" }}>
            <Button
              style={{ fontSize: "0.8rem", color: colors.primary }}
              size="small"
              shape="round"
              type="text"
              onClick={() => navigate(`/product/${productId}/relate-post`)}>
              All relate posts
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

export default RelatedPosts;
