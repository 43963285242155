import "../Perfumes.css";
import { useNavigate } from "react-router-dom";
import { Card, Flex } from "antd";
// import { MessageOutlined } from "@ant-design/icons";
import Rating from "../../../components/Rating";

const imgStyle = {
  padding: "0px 64px 8px 64px",
  position: "relative",
  transform: "translateY(-24px)",
};

const { Meta } = Card;

const CardPerfume = ({ item, ...props }) => {
  const navigate = useNavigate();

  return (
    <div {...props}>
      <Card
        hoverable
        className="vfra-card-popular"
        bordered={false}
        cover={<img style={imgStyle} alt="" src={item.image_url} />}
        onClick={() => navigate(`/product/${item.id}`)}>
        <Meta
          title={item.name}
          description={
            <Flex align="center" justify="space-between">
              <Rating value={item.rating} />
            </Flex>
          }
        />
      </Card>
    </div>
  );
};

export default CardPerfume;
