import "./UserInfo.css";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Divider, Avatar, Flex, Input, Button, message, Upload, Skeleton, Space } from "antd";
import { UserOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  updateUserInfo,
  getAvatarUploadKey,
  uploadToSignedURL,
  getPublicUrl,
  getUser,
} from "../../../services/supabaseClient";
import { imageConverterToJpg } from "../../../utils/service";
import Follow from "./Follow/Follow";

const { TextArea } = Input;

const UserInfo = () => {
  const { isMobileView } = useWindowSize();
  const { user, refreshVfraUser } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const [loading, setLoading] = useState(true);
  const [vfraUser, setVfraUser] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const [editNameMode, setEditNameMode] = useState(false);
  const [valueUsername, setValueUsername] = useState(user?.vfra_user?.name ?? user?.name);

  const [editDescriptionMode, setEditDescriptionMode] = useState(false);
  const [valueDescription, setValueDescription] = useState(null);

  const getVfraUser = useCallback(async (userId) => {
    try {
      const { error, data } = await getUser(userId);
      if (error) {
        message.error(error);
      } else {
        const vfra_user = data.length > 0 ? data[0] : null;
        setVfraUser({
          vfra_user,
        });
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user?.userId === userId) {
      setVfraUser(user);
    } else {
      getVfraUser(userId);
    }
  }, [user, userId, getVfraUser]);

  const handleEditName = () => {
    setValueUsername(user?.vfra_user?.name ?? user?.name);
    setEditNameMode(true);
  };

  const handleEditDescription = () => {
    setValueDescription(user?.vfra_user?.description ?? null);
    setEditDescriptionMode(true);
  };

  const handleOnFocusDescriptionArea = () => {
    setEditDescriptionMode(true);
  };

  const handleOnBlurDescriptionArea = () => {
    setTimeout(() => {
      setEditDescriptionMode(true);
    }, 0);
  };

  const onDescriptionChange = (value) => {
    setValueDescription(value);
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        name: null,
        description: null,
      };

      if (editNameMode) {
        if (!valueUsername) {
          message.warning("Username is invalid!");
          return;
        } else {
          payload.name = valueUsername;
        }
      } else {
        delete payload.name;
      }

      if (editDescriptionMode) {
        if (!valueDescription) {
          message.warning("Description is invalid!");
          return;
        } else {
          payload.description = valueDescription;
        }
      } else {
        delete payload.description;
      }

      setIsSubmiting(true);
      const { error } = await updateUserInfo(payload);
      if (error) {
        message.error(error);
      } else {
        refreshVfraUser();
        setEditNameMode(false);
        setEditDescriptionMode(false);
        message.success("Updated successfully!");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  //handle avatar upload
  const [uploadMode, setUploadMode] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState([]);

  const beforeUpload = (file) => {
    setUploadMode(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      setUploadMode(false);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      setUploadMode(false);
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);

    return false;
  };

  const handleChange = (info) => {
    setFile(info.file);
  };

  const onSubmitUpload = async () => {
    try {
      setIsSubmiting(true);
      const { error, data } = await getAvatarUploadKey();

      if (error) {
        message.error(error.message);
      } else {
        const { path, token } = data;

        //convert image to jpg
        const convertRes = await imageConverterToJpg(file, 2);

        if (convertRes.error) {
          message.error(convertRes.error.message);
        } else {
          const res1 = await uploadToSignedURL("avatars", path, token, convertRes.data);

          if (res1.error) {
            message.error(res1.error.message);
          } else {
            const { path } = res1.data;
            const res2 = await getPublicUrl("avatars", path);

            if (res2.error) {
              message.error(res2.error.message);
            } else {
              const { publicUrl } = res2.data;
              const payload = {
                avatar: publicUrl,
              };
              const res3 = await updateUserInfo(payload);

              if (res3.error) {
                message.error(res3.error.message);
              } else {
                refreshVfraUser();
                setUploadMode(false);
                message.success("Avatar updated successfully!");
              }
            }
          }
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  const sizeEditButton = "small";

  return (
    <Card style={{ width: "100%" }} styles={{ body: { padding: "6px" } }} className="vfra-user-infor-container">
      <Divider>
        {loading && <Skeleton.Avatar active={true} size={isMobileView ? 64 : 96} />}

        {!loading && !imageUrl && (
          <Avatar
            src={vfraUser?.vfra_user?.avatar ?? vfraUser?.avatar_url}
            size={isMobileView ? 64 : 96}
            icon={<UserOutlined />}
          />
        )}

        {!loading && imageUrl && <Avatar src={imageUrl} size={isMobileView ? 64 : 96} />}

        {!loading && isYourself && (
          <Upload name="avatar" showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
            {isYourself && !uploadMode && vfraUser && (
              <Button
                style={{ position: "absolute", marginTop: "-22px" }}
                size={sizeEditButton}
                type="text"
                shape="circle"
                icon={<EditOutlined />}
              />
            )}
          </Upload>
        )}

        {!loading && isYourself && uploadMode && (
          <Button
            style={{ position: "absolute", marginTop: "36px", marginLeft: "6px" }}
            size={sizeEditButton}
            onClick={onSubmitUpload}
            loading={isSubmiting}
            shape="circle"
            icon={<CheckOutlined />}
          />
        )}
      </Divider>

      {loading && (
        <Flex vertical gap={12} justify="center" align="center">
          <Skeleton.Input active={true} size="small" />
          <Skeleton.Input active={true} size="small" style={{ width: "40vw" }} />
        </Flex>
      )}

      {!loading && vfraUser && (
        <Flex vertical align="center">
          {!editNameMode && (
            <Flex align="center" style={{ position: "relative" }}>
              <span style={{ fontWeight: "bold", fontSize: "1.125rem" }}>
                {vfraUser?.vfra_user?.name ?? vfraUser?.name}
              </span>
              {isYourself && (
                <Button
                  style={{ position: "absolute", right: "-24px" }}
                  onClick={handleEditName}
                  size={sizeEditButton}
                  type="text"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              )}
            </Flex>
          )}

          {isYourself && editNameMode && (
            <Flex align="center">
              <Input
                value={valueUsername}
                onChange={(e) => setValueUsername(e.target.value)}
                placeholder="enter your username..."
                variant="filled"
              />
              <Button
                style={{ marginLeft: "6px" }}
                size={sizeEditButton}
                onClick={handleSubmit}
                loading={isSubmiting}
                shape="circle"
                icon={<CheckOutlined />}
              />
            </Flex>
          )}

          {isYourself && (editDescriptionMode || !vfraUser.vfra_user?.description) && (
            <Flex style={{ width: "100%", marginTop: "12px", position: "relative" }} align="center">
              <TextArea
                style={{ marginBottom: "12px" }}
                value={valueDescription}
                onChange={(e) => onDescriptionChange(e.target.value)}
                onFocus={handleOnFocusDescriptionArea}
                onBlur={handleOnBlurDescriptionArea}
                showCount={editDescriptionMode}
                placeholder="Share somthings about yourself..."
                autoSize={{
                  minRows: 1,
                }}
                maxLength={200}
                variant={editDescriptionMode ? "filled" : "borderless"}
              />

              {valueDescription && (
                <Button
                  style={{ marginLeft: "6px" }}
                  size={sizeEditButton}
                  onClick={handleSubmit}
                  loading={isSubmiting}
                  shape="circle"
                  icon={<CheckOutlined />}
                />
              )}
            </Flex>
          )}

          {!editDescriptionMode && vfraUser.vfra_user?.description && (
            <Flex style={{ width: isMobileView ? "100%" : "95%", marginTop: "6px" }} justify="center">
              <span style={{ position: "relative", textAlign: "center" }}>
                {vfraUser.vfra_user?.description}
                {isYourself && (
                  <Button
                    style={{ position: "absolute", right: "-24px" }}
                    onClick={handleEditDescription}
                    size={sizeEditButton}
                    type="text"
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                )}
              </span>
            </Flex>
          )}
        </Flex>
      )}

      {vfraUser && <Follow vfraUser={vfraUser.vfra_user} isYourself={isYourself} loading={loading} />}

      {loading && (
        <Flex justify="center" style={{ marginTop: "6px" }}>
          <Space size={12}>
            <Skeleton.Input active={true} size="small" block={true} />
            <Skeleton.Input active={true} size="small" block={true} />
          </Space>
        </Flex>
      )}
    </Card>
  );
};

export default UserInfo;
