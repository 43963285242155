export const optionDataVotes = [
  {
    id: "longevity",
    name: "Longevity",
    list: [
      {
        title: "eternal",
        value: 4,
        isActive: false,
      },
      {
        title: "long lasting",
        value: 3,
        isActive: false,
      },
      {
        title: "moderate",
        value: 2,
        isActive: false,
      },
      {
        title: "weak",
        value: 1,
        isActive: false,
      },
      {
        title: "very weak",
        value: 0,
        isActive: true,
      },
    ],
  },
  {
    id: "sillage",
    name: "Sillage",
    list: [
      {
        title: "enormous",
        value: 3,
        isActive: true,
      },
      {
        title: "strong",
        value: 2,
        isActive: false,
      },
      {
        title: "moderate",
        value: 1,
        isActive: false,
      },
      {
        title: "intimate",
        value: 0,
        isActive: false,
      },
    ],
  },
  {
    id: "price_value",
    name: "Price value",
    list: [
      {
        title: "great value",
        value: 4,
        isActive: false,
      },
      {
        title: "good value",
        value: 3,
        isActive: false,
      },
      {
        title: "ok",
        value: 2,
        isActive: true,
      },
      {
        title: "overpriced",
        value: 1,
        isActive: false,
      },
      {
        title: "way overpriced",
        value: 0,
        isActive: false,
      },
    ],
  },
];

export const initDataVotes = {
  rate: null,
  longevity: null,
  sillage: null,
  price_value: null,
  genders: {
    male: 0,
    female: 0,
    unisex: 0,
  },
  seasons: {
    winter: 0,
    spring: 0,
    summer: 0,
    fall: 0,
  },
  weathers: {
    day: 0,
    night: 0,
  },
};

export const popular_perfumes = [
  {
    id: "aventus-creed",
    name: "Aventus Creed for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.9828.jpg",
    brand_id: "creed",
    brand_name: "Creed",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.50.jpg",
    rating: 4.35,
  },
  {
    id: "reflection-man-amouage",
    name: "Reflection Man Amouage for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.920.jpg",
    brand_id: "amouage",
    brand_name: "Amouage",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.122.jpg",
    rating: 4.37,
  },
  {
    id: "oud-wood-tom-ford",
    name: "Oud Wood Tom Ford for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.1826.jpg",
    brand_id: "tom-ford",
    brand_name: "Tom Ford",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.139.jpg",
    rating: 4.33,
  },
  {
    id: "elysium-pour-homme-parfum-roja-dove",
    name: "Elysium Pour Homme Parfum Roja Dove for men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.47480.jpg",
    brand_id: "roja-dove",
    brand_name: "Roja Dove",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.626.jpg",
    rating: 4.44,
  },
  {
    id: "good-girl-gone-bad-by-kilian",
    name: "Good Girl Gone Bad By Kilian for women",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.15924.jpg",
    brand_id: "by-kilian",
    brand_name: "By Kilian",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.491.jpg",
    rating: 3.82,
  },
  {
    id: "oud-satin-mood-extrait-de-parfum-maison-francis-kurkdjian",
    name: "Oud Satin Mood Extrait de parfum Maison Francis Kurkdjian for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.48463.jpg",
    brand_id: "maison-francis-kurkdjian",
    brand_name: "Maison Francis Kurkdjian",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.770.jpg",
    rating: 4.36,
  },
  {
    id: "hacivat-nishane",
    name: "Hacivat Nishane for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.44174.jpg",
    brand_id: "nishane",
    brand_name: "Nishane",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.2232.jpg",
    rating: 4.36,
  },
  {
    id: "coco-mademoiselle-chanel",
    name: "Coco Mademoiselle Chanel for women",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.611.jpg",
    brand_id: "chanel",
    brand_name: "Chanel",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.30.jpg",
    rating: 4.12,
  },
  {
    id: "tam-dao-eau-de-parfum-diptyque",
    name: "Tam Dao Eau de Parfum Diptyque for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.49104.jpg",
    brand_id: "diptyque",
    brand_name: "Diptyque",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.421.jpg",
    rating: 4.16,
  },
  {
    id: "afternoon-swim-louis-vuitton",
    name: "Afternoon Swim Louis Vuitton for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.53947.jpg",
    brand_id: "louis-vuitton",
    brand_name: "Louis Vuitton",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.2747.jpg",
    rating: 4.33,
  },
  {
    id: "another-13-le-labo",
    name: "Another 13 Le Labo for women and men",
    image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.10131.jpg",
    brand_id: "le-labo",
    brand_name: "Le Labo",
    brand_logo: "https://img.perfumerates.com/hizme/rzjeaijii/i.403.jpg",
    rating: 4.02,
  },
];

export const countries = [
  {
    name: "Australia",
    img: "https://flagcdn.com/w80/au.png",
  },
  {
    name: "Brazil",
    img: "https://flagcdn.com/w80/br.png",
  },
  {
    name: "Canada",
    img: "https://flagcdn.com/w80/ca.png",
  },
  {
    name: "France",
    img: "https://flagcdn.com/w80/fr.png",
  },
  {
    name: "Germany",
    img: "https://flagcdn.com/w80/de.png",
  },
  {
    name: "Greece",
    img: "https://flagcdn.com/w80/gr.png",
  },
  {
    name: "Italy",
    img: "https://flagcdn.com/w80/it.png",
  },
  {
    name: "Japan",
    img: "https://flagcdn.com/w80/jp.png",
  },
  {
    name: "Netherlands",
    img: "https://flagcdn.com/w80/nl.png",
  },
  {
    name: "Oman",
    img: "https://flagcdn.com/w80/om.png",
  },
  {
    name: "Poland",
    img: "https://flagcdn.com/w80/pl.png",
  },
  {
    name: "Russia",
    img: "https://flagcdn.com/w80/ru.png",
  },
  {
    name: "Saudi Arabia",
    img: "https://flagcdn.com/w80/sa.png",
  },
  {
    name: "Spain",
    img: "https://flagcdn.com/w80/es.png",
  },
  {
    name: "Sweden",
    img: "https://flagcdn.com/w80/se.png",
  },
  {
    name: "Switzerland",
    img: "https://flagcdn.com/w80/ch.png",
  },
  {
    name: "Thailand",
    img: "https://flagcdn.com/w80/th.png",
  },
  {
    name: "Turkey",
    img: "https://flagcdn.com/w80/tr.png",
  },
  {
    name: "United Arab Emirates",
    img: "https://flagcdn.com/w80/ae.png",
  },
  {
    name: "United Kingdom",
    img: "https://flagcdn.com/w80/gb.png",
  },
  {
    name: "United States",
    img: "https://flagcdn.com/w80/us.png",
  },
  {
    name: "Argentina",
    img: "https://flagcdn.com/w80/ar.png",
  },
  {
    name: "Armenia",
    img: "https://flagcdn.com/w80/am.png",
  },
  {
    name: "Aruba",
    img: "https://flagcdn.com/w80/aw.png",
  },
  {
    name: "Austria",
    img: "https://flagcdn.com/w80/at.png",
  },
  {
    name: "Azerbaijan",
    img: "https://flagcdn.com/w80/az.png",
  },
  {
    name: "Bahrain",
    img: "https://flagcdn.com/w80/bh.png",
  },
  {
    name: "Bangladesh",
    img: "https://flagcdn.com/w80/bd.png",
  },
  {
    name: "Barbados",
    img: "https://flagcdn.com/w80/bb.png",
  },
  {
    name: "Belarus",
    img: "https://flagcdn.com/w80/by.png",
  },
  {
    name: "Belgium",
    img: "https://flagcdn.com/w80/be.png",
  },
  {
    name: "Belorussia",
    img: "https://via.placeholder.com/64/fff?text=Belorussia",
  },
  {
    name: "Bermuda",
    img: "https://flagcdn.com/w80/bm.png",
  },
  {
    name: "Bulgaria",
    img: "https://flagcdn.com/w80/bg.png",
  },
  {
    name: "Cabo Verde",
    img: "https://via.placeholder.com/64/fff?text=Cabo Verde",
  },
  {
    name: "Cayman Islands",
    img: "https://flagcdn.com/w80/ky.png",
  },
  {
    name: "Chile",
    img: "https://flagcdn.com/w80/cl.png",
  },
  {
    name: "China",
    img: "https://flagcdn.com/w80/cn.png",
  },
  {
    name: "Colombia",
    img: "https://flagcdn.com/w80/co.png",
  },
  {
    name: "Cote D’Ivoire",
    img: "https://via.placeholder.com/64/fff?text=Cote D’Ivoire",
  },
  {
    name: "Croatia",
    img: "https://flagcdn.com/w80/hr.png",
  },
  {
    name: "Cuba",
    img: "https://flagcdn.com/w80/cu.png",
  },
  {
    name: "Cyprus",
    img: "https://flagcdn.com/w80/cy.png",
  },
  {
    name: "Czech Republic",
    img: "https://flagcdn.com/w80/cz.png",
  },
  {
    name: "Denmark",
    img: "https://flagcdn.com/w80/dk.png",
  },
  {
    name: "Dominican Republic",
    img: "https://flagcdn.com/w80/do.png",
  },
  {
    name: "Dubai",
    img: "https://via.placeholder.com/64/fff?text=Dubai",
  },
  {
    name: "Ecuador",
    img: "https://flagcdn.com/w80/ec.png",
  },
  {
    name: "Egypt",
    img: "https://flagcdn.com/w80/eg.png",
  },
  {
    name: "Estonia",
    img: "https://flagcdn.com/w80/ee.png",
  },
  {
    name: "Finland",
    img: "https://flagcdn.com/w80/fi.png",
  },
  {
    name: "Georgia",
    img: "https://flagcdn.com/w80/ge.png",
  },
  {
    name: "Ghana",
    img: "https://flagcdn.com/w80/gh.png",
  },
  {
    name: "Guatemala",
    img: "https://flagcdn.com/w80/gt.png",
  },
  {
    name: "Hong Kong",
    img: "https://flagcdn.com/w80/hk.png",
  },
  {
    name: "Hungary",
    img: "https://flagcdn.com/w80/hu.png",
  },
  {
    name: "Iceland",
    img: "https://flagcdn.com/w80/is.png",
  },
  {
    name: "India",
    img: "https://flagcdn.com/w80/in.png",
  },
  {
    name: "Indonesia",
    img: "https://flagcdn.com/w80/id.png",
  },
  {
    name: "Iran",
    img: "https://flagcdn.com/w80/ir.png",
  },
  {
    name: "Ireland",
    img: "https://flagcdn.com/w80/ie.png",
  },
  {
    name: "Israel",
    img: "https://flagcdn.com/w80/il.png",
  },
  {
    name: "Jordan",
    img: "https://flagcdn.com/w80/jo.png",
  },
  {
    name: "Kazakhstan",
    img: "https://flagcdn.com/w80/kz.png",
  },
  {
    name: "Kenya",
    img: "https://flagcdn.com/w80/ke.png",
  },
  {
    name: "Kuwait",
    img: "https://flagcdn.com/w80/kw.png",
  },
  {
    name: "Latvia",
    img: "https://flagcdn.com/w80/lv.png",
  },
  {
    name: "Lebanon",
    img: "https://flagcdn.com/w80/lb.png",
  },
  {
    name: "Liberia",
    img: "https://flagcdn.com/w80/lr.png",
  },
  {
    name: "Lithuania",
    img: "https://flagcdn.com/w80/lt.png",
  },
  {
    name: "Luxembourg",
    img: "https://flagcdn.com/w80/lu.png",
  },
  {
    name: "Malaysia",
    img: "https://flagcdn.com/w80/my.png",
  },
  {
    name: "Malta",
    img: "https://flagcdn.com/w80/mt.png",
  },
  {
    name: "Mexico",
    img: "https://flagcdn.com/w80/mx.png",
  },
  {
    name: "Moldavia",
    img: "https://via.placeholder.com/64/fff?text=Moldavia",
  },
  {
    name: "Monaco",
    img: "https://flagcdn.com/w80/mc.png",
  },
  {
    name: "Morocco",
    img: "https://flagcdn.com/w80/ma.png",
  },
  {
    name: "Myanmar",
    img: "https://flagcdn.com/w80/mm.png",
  },
  {
    name: "New Zealand",
    img: "https://flagcdn.com/w80/nz.png",
  },
  {
    name: "Nigeria",
    img: "https://flagcdn.com/w80/ng.png",
  },
  {
    name: "Norway",
    img: "https://flagcdn.com/w80/no.png",
  },
  {
    name: "Pakistan",
    img: "https://flagcdn.com/w80/pk.png",
  },
  {
    name: "Peru",
    img: "https://flagcdn.com/w80/pe.png",
  },
  {
    name: "Philippines",
    img: "https://flagcdn.com/w80/ph.png",
  },
  {
    name: "Portugal",
    img: "https://flagcdn.com/w80/pt.png",
  },
  {
    name: "Puerto Rico",
    img: "https://flagcdn.com/w80/pr.png",
  },
  {
    name: "Qatar",
    img: "https://flagcdn.com/w80/qa.png",
  },
  {
    name: "Romania",
    img: "https://flagcdn.com/w80/ro.png",
  },
  {
    name: "Rwanda",
    img: "https://flagcdn.com/w80/rw.png",
  },
  {
    name: "Serbia",
    img: "https://flagcdn.com/w80/rs.png",
  },
  {
    name: "Seychelles",
    img: "https://flagcdn.com/w80/sc.png",
  },
  {
    name: "Singapore",
    img: "https://flagcdn.com/w80/sg.png",
  },
  {
    name: "Slovakia",
    img: "https://flagcdn.com/w80/sk.png",
  },
  {
    name: "South Africa",
    img: "https://flagcdn.com/w80/za.png",
  },
  {
    name: "South Korea",
    img: "https://via.placeholder.com/64/fff?text=South Korea",
  },
  {
    name: "Sri Lanka",
    img: "https://flagcdn.com/w80/lk.png",
  },
  {
    name: "St. Barts",
    img: "https://via.placeholder.com/64/fff?text=St. Barts",
  },
  {
    name: "Tahiti",
    img: "https://via.placeholder.com/64/fff?text=Tahiti",
  },
  {
    name: "Taiwan",
    img: "https://flagcdn.com/w80/tw.png",
  },
  {
    name: "Tenerife",
    img: "https://via.placeholder.com/64/fff?text=Tenerife",
  },
  {
    name: "Ukraine",
    img: "https://flagcdn.com/w80/ua.png",
  },
  {
    name: "Uzbekistan",
    img: "https://flagcdn.com/w80/uz.png",
  },
  {
    name: "Vietnam",
    img: "https://flagcdn.com/w80/vn.png",
  },
  {
    name: "Wales",
    img: "https://via.placeholder.com/64/fff?text=Wales",
  },
  {
    name: "Zambia",
    img: "https://flagcdn.com/w80/zm.png",
  },
];

export const mockRelatedPosts = [
  {
    post: {
      id: "71df46e2-17d4-477e-b4cd-bc5583e1cb57",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "This smells like apple cider at first. Doesn't smell like a summer perfume at all. Not a huge fan honestly but will keep because the bottle is gorgeous",
      perfumes: [
        {
          id: "sexy-little-things-noir-summer-victoria-s-secret",
          name: "Sexy Little Things Noir Summer",
          accords: [
            {
              name: "white flowers",
              count: 1,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "fruits & vegetables",
              count: 1,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.12224.jpg",
          brand_name: "Victoria's Secret",
          brand_country: "United States",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.12224.jpg"],
      },
      created_at: "2024-09-12T18:20:32.250783+00:00",
      total_comments: 0,
      total_hearts: 3,
      total_saves: 2,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 1,
    },
    meta: {},
  },
  {
    post: {
      id: "6cfb18f7-a8b8-4252-8e31-db52c188846e",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "well.... what to say about this....the story goes: i ordered Salvador Dali from the 1985 (the one with orange liquid) but instead got this one....i can't say i'm angry cause finally i've got a SALVADOR DALI bottle in my collection and i love it :D he is my role model and my idol so i don't care if i have a female fragrance on me as long as it has his name on it.... international day of happiness is very happy for me :D i couldn't stop smiling today after DALI arrived :D \nnow the fragrance: ironically this is a spring scent and today is the first day of spring and it arrived today, the bottle is amazing and very decorative but the juice OMG... i love it, i can smell musk mixed with jasmine at the same time but not so much girly like i expected, a man can pull this off easy, it opens with brief fabric softener like smell but after a second it becomes divine :) the magic thing about this one is i tried it in the house and it gets strong but fades to skin after about half an hour but when you get outside on the fresh spring air it suddenly gets very strong and very pleasant, it's warm and happy scent :D just wow, it was a blind buy but well worth it, nobody in my small town has a scent like this so it will draw some attention and i won't be embarrassed to say \"I WEAR DALI\"  \nif it was a DIOR fragrance i'd put it in the POISON line...something like WILD POISON or something like that \nif i would see a famous person wear this i would see JEAN HARLOW :)",
      perfumes: [
        {
          id: "dali-eau-de-toilette-salvador-dali",
          name: "Dali Eau de Toilette",
          accords: [
            {
              name: "white flowers",
              count: 2,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "flowers",
              count: 2,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "citrus",
              count: 2,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "woods & mosses",
              count: 1,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.13072.jpg",
          brand_name: "Salvador Dali",
          brand_country: "Spain",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.13072.jpg"],
      },
      created_at: "2024-09-12T18:18:36.250631+00:00",
      total_comments: 0,
      total_hearts: 3,
      total_saves: 4,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 4,
    },
    meta: {},
  },
  {
    post: {
      id: "4a641943-57a7-4ba7-8e5c-8c56eb72ef51",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "If Demeter's Vanilla Cake Batter is a warm vanilla, their Vanilla Ice Cream is a cool vanilla. I personally like the warmer vanilla more. I do have to say, this is surprisingly authentic - it even has a slight freezer smell! If you want to smell like an ice cream shop, grab this one! ;)",
      perfumes: [
        {
          id: "vanilla-ice-cream-demeter-fragrance",
          name: "Vanilla Ice Cream",
          accords: [
            {
              name: "sweets",
              count: 1,
              color_code: "rgb(232, 209, 77)",
            },
            {
              name: "natural & weird",
              count: 1,
              color_code: "rgb(230,130,55)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.7757.jpg",
          brand_name: "Demeter Fragrance",
          brand_country: "United States",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.7757.jpg"],
      },
      created_at: "2024-09-12T18:20:37.397229+00:00",
      total_comments: 4,
      total_hearts: 3,
      total_saves: 3,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 4,
    },
    meta: {},
  },
  {
    post: {
      id: "2e802313-ebc6-4098-8fd4-0ec16eeace32",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "About ten years or so ago I tried this in a perfume boutique and I remember really liking it. I wish I could be specific as I am with other reviews, but I can’t remember exactly what it was that grabbed my attention but it was so gorgeous. I don’t remember it being that much like the original with that intense tuberose. I think it was the Chinese osmathus and plum with citrusy notes which blended beautifully. The main thing i remember is my regret in leaving the store without it!. A while later I tried to find it but haven’t had any luck. If I ever see a bottle on eBay I’ll buy it and come back to update this review. I’m surprised there aren’t any reviews on here, it must have not been a big seller. As it’s been so long I doubt I’ll find a bottle but if you have this and willing to part (swap/sell) please let me know.",
      perfumes: [
        {
          id: "very-michael-kors-michael-kors",
          name: "Very Michael Kors",
          accords: [
            {
              name: "white flowers",
              count: 1,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "fruits & vegetables",
              count: 1,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "flowers",
              count: 1,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "citrus",
              count: 1,
              color_code: "rgb(177, 227, 50)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.2402.jpg",
          brand_name: "Michael Kors",
          brand_country: "United States",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.2402.jpg"],
      },
      created_at: "2024-09-12T18:14:05.286635+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 3,
    },
    meta: {},
  },
  {
    post: {
      id: "e5e21b78-54a7-44e3-87c4-20214198c00a",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "Beautiful juice, sweet and powerful in the same time. The patchouly is ambery-sweet, warm, rounded by almond and a hint of peach (without being a clear fruity fragrance. It more a color accord)\nIt is a perfect fall scent, a kind of connection to your roots. \nThe lasting power is incredible. The little paper to test it is still smelling good after 2 weeks, and a forgotten scarf 2 months (I didn’t even remember what it was and was searching all around who was smelling that good!)\n\nEdit: I think it was renamed as Ambre Talisman?",
      perfumes: [
        {
          id: "patchouly-vanille-adopt-parfums",
          name: "Patchouly Vanille",
          accords: [
            {
              name: "fruits & vegetables",
              count: 2,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "woods & mosses",
              count: 1,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.23450.jpg",
          brand_name: "Adopt Parfums",
          brand_country: "France",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.23450.jpg"],
      },
      created_at: "2024-09-12T18:20:22.240515+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 0,
    },
    meta: {},
  },
  {
    post: {
      id: "34dd6a66-b24b-493f-8b5b-76ef3956b039",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "The initial blast and my first impression was the dreaded hyacinths... but it mellowed out and brought other notes forward to support it thankfully. \nIt's got a strong vintage feel to it, it by no means smells modern. My mother said it reminded her of Chanel No. 5, I'm assuming it was the aldehydes she could smell. \nNow, several months later this holds a strange place in my heart.\nI never expected love, the set was cheap and frankly I was just on a desperate perfume binge (you know how the stages develop, this was the buy everything point).  \nDespite being wary of hyacinths, the aldehydes and peach are just so crisp up top, it's beautiful.\nYes, then there is a soapy smell and yes, it's vintage. Still pretty thoughard. Floral yet spicy but also green. \nIt dries down with a surprisingly deep tone, oakmoss and leather I feel.\nI'd love to have smelt the original.  \nLasts rather a while (rare for me) and projects well. One of the few I would definitely replace. It just reminds me of a strong, well scrubbed woman, someone respectable that you could admire; this is no girly girl or femme fatale. I wear it when I need determination!",
      perfumes: [
        {
          id: "k-de-krizia-krizia",
          name: "K de Krizia",
          accords: [
            {
              name: "white flowers",
              count: 3,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "fruits & vegetables",
              count: 1,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "flowers",
              count: 7,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "citrus",
              count: 2,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "resins & balsams",
              count: 1,
              color_code: "rgb(54,34,22)",
            },
            {
              name: "woods & mosses",
              count: 3,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "natural & weird",
              count: 1,
              color_code: "rgb(230,130,55)",
            },
            {
              name: "musk, amber and animalic",
              count: 4,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.2833.jpg",
          brand_name: "Krizia",
          brand_country: "Italy",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.2833.jpg"],
      },
      created_at: "2024-09-12T17:22:04.2103+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 4,
    },
    meta: {},
  },
  {
    post: {
      id: "33cf8571-38eb-4a7f-9042-63f22fbda138",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "Sweet, floral, I love it ! Such a surprise to me : joyful and warm, but fancy. It's a kind of Fancy - Jessica Simpson or Amor Amor In a Flash - Cacharel : a sophisticated floral-gourmand.\nVery good lasting power. \nBut the bottle is awful : please change this bottle ! It has nothing in common with the fragrance. Fortunately, bottles don't make the perfume.",
      perfumes: [
        {
          id: "vertige-id-parfums",
          name: "Vertige",
          accords: [
            {
              name: "fruits & vegetables",
              count: 2,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "citrus",
              count: 2,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "flowers",
              count: 3,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "resins & balsams",
              count: 1,
              color_code: "rgb(54,34,22)",
            },
            {
              name: "woods & mosses",
              count: 2,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.10637.jpg",
          brand_name: "ID Parfums",
          brand_country: "France",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.10637.jpg"],
      },
      created_at: "2024-09-12T17:47:26.104043+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 5,
    },
    meta: {},
  },
  {
    post: {
      id: "0d7a5fb4-b96c-4371-9077-a3097ae2909b",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "Le Fabuleux by Nejma. A fabulous childhood\n\nI didn't find many details in groups and in Fragcom about this perfume, instead the ingredients determined me to buy it. Cherries black, iris, violet, ambergris, incense..well. Who wouldn't be curious to even test it. A niche perfume without a doubt that exudes joy through all pores, serenity. He puts a smile on your face. It transports me in time, during my childhood spent spring-summer among cherries and green grass.\n\n\nThe composition of the perfume is very attractive. The first fluff hits you with the aroma of black cherries, a sweet bitter and slightly smoky smell. Practically think at the juice of black cherries that give off that fruity scent, or rather the sweetness of bitter cherries just taken from the fire after boiling for hours on a wooden bed. For me, the bitter cherries in the opening and the incense are the notes that give weight to the perfume. I can't tell the iris. Maybe it's there, but towards the end of life, the perfume emits an almost metallic scent specific to violet.\n\nThe performance of the perfume is above average, slightly better than the vast majority of those you find on the shelves. However, a composition that is detached from the pattern and cannot be included in the generic range of perfumes with cherries given by tobacco.\nThe fruity opening may look feminine in the first moments, after spraying. Undoubtedly to be worn in spring and in the first part of summer when the temperatures are not very high.\n\nI think this perfume is worth a little revelation that deserves to be discovered. Undoubtedly, his breezes embrace you and throw you into a sea of sunshine, in a period of childhood or youth without headaches and dramas. A pill of joy.",
      perfumes: [
        {
          id: "le-fabuleux-nejma",
          name: "Le Fabuleux",
          accords: [
            {
              name: "greens & herbs",
              count: 1,
              color_code: "rgb(51,204,0)",
            },
            {
              name: "fruits & vegetables",
              count: 1,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "flowers",
              count: 2,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "resins & balsams",
              count: 1,
              color_code: "rgb(54,34,22)",
            },
            {
              name: "woods & mosses",
              count: 1,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 4,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.49599.jpg",
          brand_name: "Nejma",
          brand_country: "France",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.49599.jpg"],
      },
      created_at: "2024-09-12T17:44:22.115847+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 3,
    },
    meta: {},
  },
  {
    post: {
      id: "f5d5b409-9a09-4432-9aa6-064bfcac7fec",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "Unlike the last two summer editions this one smells a lot like the original edt but it's fresh and not as cloying as the edt (which I dislike)...need to test it again, might buy it....also because I love Betty Boop and the bottle is sooo cute! I tested it on the back of my hand and longevity is pretty good for an eau fraiche (on paper strip it's still strong after three-four days)!",
      perfumes: [
        {
          id: "classique-betty-boop-eau-fraiche-jean-paul-gaultier",
          name: "Classique Betty Boop Eau Fraiche",
          accords: [
            {
              name: "white flowers",
              count: 3,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "citrus",
              count: 1,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "resins & balsams",
              count: 1,
              color_code: "rgb(54,34,22)",
            },
            {
              name: "sweets",
              count: 2,
              color_code: "rgb(232, 209, 77)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 2,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.36635.jpg",
          brand_name: "Jean Paul Gaultier",
          brand_country: "France",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.36635.jpg"],
      },
      created_at: "2024-09-12T16:42:06.246583+00:00",
      total_comments: 0,
      total_hearts: 2,
      total_saves: 1,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 5,
    },
    meta: {},
  },
  {
    post: {
      id: "c29a45ed-7029-44a0-a9c1-f98473121bba",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "It is one of the fruitiest perfumes I've smelled, the hibiscus that looks like mango with lemon make me think of a fruit cocktail with chamoy, if you have a daughter who does not like floral or very complicated perfumes, this is a good choice, it is interesting for the woody base that is barely perceived, but the truth very youthful, the duration is 4 hours on skin and about 8 on clothes, but it is an eau de toilette so for me it's fine. <3\nFor some it may be synthetic, but for me, it reminds me of the Bratz dolls, this perfume would be worn by Jade in the Punk version.",
      perfumes: [
        {
          id: "double-nature-sexy-jafra",
          name: "Double Nature Sexy",
          accords: [
            {
              name: "fruits & vegetables",
              count: 4,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "citrus",
              count: 2,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "sweets",
              count: 1,
              color_code: "rgb(232, 209, 77)",
            },
            {
              name: "woods & mosses",
              count: 1,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.50704.jpg",
          brand_name: "JAFRA",
          brand_country: "United States",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.50704.jpg"],
      },
      created_at: "2024-09-12T17:57:49.258613+00:00",
      total_comments: 0,
      total_hearts: 1,
      total_saves: 0,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 3,
    },
    meta: {},
  },
  {
    post: {
      id: "6cce5ae3-9e90-4e09-ac61-5fdc168372c3",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "Very nice perfume for a young lady! It gives this lovely feeling of youth. I like it more then the original Eclat. The longevity feels quite okay. On my skin it stays even after the shower.",
      perfumes: [
        {
          id: "eclat-d-arpege-sheer-lanvin",
          name: "Éclat d'Arpège Sheer",
          accords: [
            {
              name: "white flowers",
              count: 1,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "fruits & vegetables",
              count: 1,
              color_code: "rgb(204,100,50)",
            },
            {
              name: "citrus",
              count: 1,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "flowers",
              count: 3,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "woods & mosses",
              count: 1,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 2,
              color_code: "rgb(83,51,42)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.63095.jpg",
          brand_name: "Lanvin",
          brand_country: "France",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.63095.jpg"],
      },
      created_at: "2024-09-12T18:13:13.244442+00:00",
      total_comments: 0,
      total_hearts: 1,
      total_saves: 1,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 4,
    },
    meta: {},
  },
  {
    post: {
      id: "4bccbd00-2b68-4456-959a-fe8b040998de",
      owner: {
        id: "16a5c8d3-e689-464b-b74a-8dcdc081fce1",
        name: "Scentuber - Perfume Lover",
        avatar:
          "https://spfycswrpbrwxqetirar.supabase.co/storage/v1/object/public/avatars/16a5c8d3-e689-464b-b74a-8dcdc081fce1.jpg?t=1725295683478",
        followed: false,
      },
      caption:
        "And this is a beautiful way to do a summer fragrance without big citrus or marine! \nOn first spray I got an overwhelming (in a good way) blast of green, the tomato leaf is so present but blended so well that the impression is of a pure photorealistic plant that hasn’t been cultivated yet. It’s light but luscious with the tea and magnolia preventing it from veering into herba fresca territory (though I love it I already have it). There is almost a garden mint type facet to this though I think it’s the tomato leaf and basil perhaps.\nIt really is a light fresh green and beautifully blended, props to the perfumer responsible.\nIf you like Herba Fresca or even ELDO You or Someone like You this might be an easy pick if you like that freshness of tomato leaf, blended superbly but not avoidable if you are not a fan.\nDamn, I was not planning on buying any more fragrance this summer!",
      perfumes: [
        {
          id: "celadon-miller-harris",
          name: "Celadon",
          accords: [
            {
              name: "white flowers",
              count: 1,
              color_code: "rgb(195, 157, 196)",
            },
            {
              name: "greens & herbs",
              count: 4,
              color_code: "rgb(51,204,0)",
            },
            {
              name: "citrus",
              count: 1,
              color_code: "rgb(177, 227, 50)",
            },
            {
              name: "flowers",
              count: 3,
              color_code: "rgb(227, 52, 183)",
            },
            {
              name: "natural & weird",
              count: 1,
              color_code: "rgb(230,130,55)",
            },
            {
              name: "woods & mosses",
              count: 2,
              color_code: "rgb(119,68,20)",
            },
            {
              name: "musk, amber and animalic",
              count: 1,
              color_code: "rgb(83,51,42)",
            },
            {
              name: "spices",
              count: 1,
              color_code: "rgb(242, 137, 56)",
            },
          ],
          image_url: "https://img.perfumerates.com/hizme/dvbjlhj/375l500.92280.jpg",
          brand_name: "Miller Harris",
          brand_country: "United Kingdom",
        },
      ],
      media_data: {
        type: "image",
        urls: ["https://fimgs.net/mdimg/perfume/375x500.92280.jpg"],
      },
      created_at: "2024-09-12T18:11:24.473948+00:00",
      total_comments: 12,
      total_hearts: 1,
      total_saves: 1,
      status: "CREATED",
      active_for_user_save: false,
      active_for_user_heart: false,
      total_vieweds: 53,
    },
    meta: {},
  },
];

export const mockSounds = [
  {
    id: "AlwaysRememberUsThisWay",
    name: "Always Remember Us This Way",
    artist: "Gloria Vanderbilt",
  },
  {
    id: "AnhDechCanGiNhieuNgoaiEm",
    name: "Anh Dech Can Gi Nhieu Ngoai Em",
    artist: "Den Vau",
  },
  {
    id: "AtMyWorst",
    name: "At My Worst",
    artist: "Wayne rooney",
  },
  {
    id: "BaiNayChillPhet",
    name: "Bai Nay Chill Phet",
    artist: "Min ft Den Vay",
  },
  {
    id: "CryingOverYou",
    name: "Crying Over You",
    artist: "Justasuy",
  },
  {
    id: "DiTheoBongMatTroi",
    name: "Di Theo Bong Mat Troi",
    artist: "Gloria Vanderbilt",
  },
  {
    id: "DuaNhauDiTron",
    name: "Dua Nhau Di Tron",
    artist: "Den Vau",
  },
];
