import { Col, Row } from "antd";
import ProductDetails from "./ProductDetails/ProductDetails";
import Comments from "./Comments/Comments";
import useWindowSize from "../../hooks/useWindowSize";

const Products = () => {
  const { isMobileView } = useWindowSize();
  return (
    <div className="vfra-container">
      <Row gutter={[32, 32]} justify="center">
        <Col span={24}>
          <ProductDetails />
        </Col>
        <Col span={!isMobileView ? 18 : 24}>
          <Comments />
        </Col>
      </Row>
    </div>
  );
};

export default Products;
