import "./OwnPerfumes.css";
import { useState, useCallback, useEffect } from "react";
import { List, Divider, Button, Tag, Modal, Space, Flex, message, Form } from "antd";
import { EditOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { getRequestPerfumes, removeRequestPerfume } from "../../../services/supabaseClient";
import PerfumeForm from "./PerfumeForm/PerfumeForm";
import useWindowSize from "../../../hooks/useWindowSize";
const PAGE_SIZE = 10;

const { confirm } = Modal;

const getStatus = (value) => {
  if (value === "accept") {
    return (
      <Tag bordered={false} color="success">
        accept
      </Tag>
    );
  } else if (value === "processing") {
    return (
      <Tag bordered={false} color="processing">
        processing
      </Tag>
    );
  } else if (value === "reject") {
    return (
      <Tag bordered={false} color="error">
        reject
      </Tag>
    );
  } else if (value === "created") {
    return (
      <Tag bordered={false} color="cyan">
        created
      </Tag>
    );
  }
};

const OwnPerfumes = () => {
  const { isMobileView } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [dataPerfume, setDataPerfume] = useState(null);
  const [listPerfume, setListPerfume] = useState([]);
  const [showLoadmore, setShowLoadmore] = useState(true);

  const fetchData = useCallback(async (startTime = 0) => {
    try {
      setIsLoading(true);
      const { error, data } = await getRequestPerfumes(startTime, PAGE_SIZE);
      if (error) {
        message.error(error.message);
      } else {
        if (data.length === 0) {
          setShowLoadmore(false);
        } else {
          if (startTime !== 0) {
            setListPerfume((prev) => [...prev, ...data]);
          } else {
            setListPerfume(data);
          }
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadmore = () => {
    const startTime = listPerfume.length > 0 ? listPerfume[listPerfume.length - 1].created_at : 0;
    fetchData(startTime);
  };

  //handle form actions
  const handleCloseForm = () => {
    form.resetFields();
    setDataPerfume(null);
    setOpen(false);
  };

  const handleFormSubmit = () => {
    form.resetFields();
    setDataPerfume(null);
    setOpen(false);
    fetchData();
  };

  const onOpenPerfumeForm = (data) => {
    form.resetFields();

    if (data) {
      setDataPerfume({ ...data, isUpdateMode: true });
    }

    setOpen(true);
  };

  const onDelete = async (item) => {
    try {
      setIsLoading(true);
      const { error } = await removeRequestPerfume(item.id);

      if (error) {
        message.error(error.message);
      } else {
        message.success("Perfume was deleted successfully!");
        fetchData();
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const showDeleteConfirm = (item) => {
    return confirm({
      title: "Confirm delete perfume",
      content: "Are you sure you want to delete this perfume?",
      centered: true,
      getContainer: document.getElementById("root"),
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onDelete(item);
      },
    });
  };

  const imageHeight = isMobileView ? 30 : 60;
  const buttonSize = isMobileView ? "small" : "middle";

  return (
    <div className="vfra-container">
      <Flex justify="space-between" align="center">
        <b>List perfume</b>
        <Space split={<Divider type="vertical" />}>
          <b>Total: {listPerfume.length}</b>
          <Button
            size={buttonSize}
            style={{ marginRight: "8px" }}
            onClick={() => onOpenPerfumeForm(null)}
            icon={<PlusOutlined />}
            shape="circle"></Button>
        </Space>
      </Flex>

      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={listPerfume}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <Button size={buttonSize} onClick={() => onOpenPerfumeForm(item)} type="text" icon={<EditOutlined />} />,
              <Button size={buttonSize} onClick={() => showDeleteConfirm(item)} type="text" icon={<CloseOutlined />} />,
            ]}>
            <List.Item.Meta
              style={{ alignItems: "center" }}
              avatar={<img alt="" height={imageHeight} src={item.image_url} />}
              title={item.name}
              description={item.brand_name}
            />
            {getStatus(item.status)}
          </List.Item>
        )}
      />

      {showLoadmore && (
        <Flex justify="center" style={{ marginTop: "12px" }}>
          <Button onClick={handleLoadmore} disabled={isLoading} size="small">
            Load more
          </Button>
        </Flex>
      )}

      <Modal
        getContainer={false}
        title={<p>{dataPerfume ? "Update perfume" : "Add perfume"}</p>}
        centered
        width={isMobileView ? "100%" : "70%"}
        open={open}
        footer={null}
        onCancel={handleCloseForm}>
        <PerfumeForm form={form} dataPerfume={dataPerfume} onSubmitForm={handleFormSubmit} />
      </Modal>
    </div>
  );
};

export default OwnPerfumes;
