import { useState, useCallback, useMemo } from "react";
import { Row, Col, Input, Popover, message } from "antd";
import _ from "lodash";
import { search } from "../../../services/musicServer";
import TabSound from "./TabSound/TabSound";
import SearchResult from "./SearchResult/SearchResult";
import useWindowSize from "../../../hooks/useWindowSize";
const PAGE_SIZE = 20;

const SearchSound = ({ onSelect }) => {
  const { isMobileView } = useWindowSize();

  const [page, setPage] = useState(1);
  const [sounds, setSounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadmore, setLoadmore] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [selectedSound, setSelectedSound] = useState(null);

  const onSearch = useCallback(async (valueSearch, newPage = 1) => {
    try {
      if (newPage > 1) setLoadmore(true);
      else setLoading(true);

      const res = await search(valueSearch, newPage, PAGE_SIZE);

      if (res[`subsonic-response`].status === "ok") {
        if (newPage > 1) {
          setSounds((prev) => [...prev, ...res[`subsonic-response`].searchResult2.song]);
        } else {
          setSounds(res[`subsonic-response`].searchResult2.song);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
      setLoadmore(false);
    }
  }, []);

  const debouncedSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch]);

  const onLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    onSearch(searchValue, newPage);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (!value) {
      setSounds([]);
    } else {
      setPage(1);
      debouncedSearch(value);
    }

    setSearchValue(value);
  };

  const handleSelectSound = (item) => {
    setSelectedSound(item);
    onSelect(item);
  };

  return (
    <Row justify="center">
      <Col span={isMobileView ? 24 : 12}>
        <Popover
          trigger="click"
          getPopupContainer={false}
          title={searchValue && <p style={{ paddingLeft: "12px" }}>Search results</p>}
          placement="bottom"
          overlayStyle={{ width: "40%" }}
          content={
            <>
              {!searchValue && <TabSound selectedSound={selectedSound} onSelect={handleSelectSound} />}

              {searchValue && (
                <SearchResult
                  loading={loading}
                  sounds={sounds}
                  selectedSound={selectedSound}
                  onSelect={handleSelectSound}
                  loadmore={loadmore}
                  onLoadMore={onLoadMore}
                />
              )}
            </>
          }>
          <Input
            size="large"
            allowClear
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search music or artist..."
          />
        </Popover>
      </Col>
    </Row>
  );
};

export default SearchSound;
