import { Flex, Spin, Button } from "antd";
import MusicItem from "../../../MusicItem/MusicItem";
import { colors } from "../../../../utils/service";

const SearchResult = ({ loading, sounds, selectedSound, onSelect, onLoadMore, loadmore }) => {
  return (
    <>
      <div style={{ height: "45vh", overflow: "scroll", padding: "6px 12px" }}>
        {loading && (
          <Flex justify="center" align="center" style={{ marginTop: "20vh" }}>
            <Spin size="small" />
          </Flex>
        )}

        {!loading && sounds.length === 0 && (
          <Flex height="100%" justify="center" align="center">
            Playlist empty!
          </Flex>
        )}

        {!loading &&
          sounds.length > 0 &&
          sounds.map((item) => (
            <MusicItem
              key={item.id}
              sound={item}
              onSelectSound={onSelect}
              isChecked={selectedSound && selectedSound.id === item.id}
              className={`vfra-sound-item ${selectedSound && selectedSound.id === item.id ? "active" : ""}`}
            />
          ))}

        {!loading && sounds.length > 0 && (
          <Flex justify="center" style={{ marginTop: "12px" }}>
            <Button
              style={{ color: colors.primary }}
              shape="round"
              size="small"
              type="text"
              onClick={onLoadMore}
              loading={loadmore}>
              More musics
            </Button>
          </Flex>
        )}
      </div>
    </>
  );
};

export default SearchResult;
