import "./CreatePost.css";
import { useState, useEffect, useRef } from "react";
import { Flex, Avatar, Button, Space, Input, Row, Col, Divider, message, Radio, Slider, Switch } from "antd";
import { CloseOutlined, BlockOutlined, CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import { createPost, uploadToSignedURL, getPostImagesUploadKey, updatePost } from "../../services/supabaseClient";
import { stream, getAvatar } from "../../services/musicServer";
import { public_image_url } from "../../utils/service";
import { musicIcon } from "../../utils/svgIcons";
import CustomeSvgIcon from "../../components/CustomSvgIcon";
import useWindowSize from "../../hooks/useWindowSize";
import ImageDragger from "./ImageDragger/ImageDragger";
import VideoDragger from "./VideoDragger/VideoDragger";
import SearchRelatePerfume from "./SearchRelatePerfume/SearchRelatePerfume";
import SearchSound from "./SoundSelector/SoundSelector";

const { TextArea } = Input;
const typeMedias = {
  image: "image",
  video: "video",
};

const CreatePost = ({ onClose, onCreate }) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();

  const [valueCaption, setValueCaption] = useState(null);
  const [typeMedia, setTypeMedia] = useState(typeMedias.image);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const onCancel = () => {
    resetForm();
  };

  const onSubmit = async () => {
    const { isValid, error } = onValidate();
    if (!isValid) {
      message.warning(error);
      return;
    }

    setIsSubmiting(true);

    try {
      const res = await createPost();

      if (res.error) {
        message.error(res.error.message);
      } else {
        const newPostId = res.data[0].id;

        const urlList = await handleUploadPostImages(newPostId, imageList);
        if (!urlList) {
          message.error("Error uploading image, please try again!");
          setIsSubmiting(false);
          return;
        }

        let perfumes = [];
        if (selectedPerfumes.length > 0) {
          perfumes = selectedPerfumes.map((item) => item.id);
        }

        let music_data = null;
        if (selectedSound) {
          music_data = {
            track_id: selectedSound.id,
            start_time: playingRange[0],
            end_time: playingRange[1],
            loop: soundLoop,
          };
        }

        const payloadUpdate = {
          caption: valueCaption.trim(),
          perfumes,
          music_data,
          media_data: { type: "image", urls: urlList },
        };

        const res1 = await updatePost(newPostId, payloadUpdate);
        if (res1.error) {
          message.error(res1.error.message);
        } else {
          message.success("Post was created successfully");

          setTimeout(() => {
            resetForm();
            onCreate();
          }, 300);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onValidate = () => {
    if (!valueCaption) {
      return {
        idValid: false,
        error: "Caption is required!",
      };
    }

    if (!imageList) {
      return {
        idValid: false,
        error: "Image is required!",
      };
    }

    return {
      isValid: true,
      error: null,
    };
  };

  const handleUploadPostImages = async (id, files) => {
    return new Promise(async (resolve, _reject) => {
      try {
        const { error, data } = await getPostImagesUploadKey(id, files.length);

        if (error) {
          message.error(error.message);
          resolve(null);
        } else {
          const urlList = [];

          for (let i = 0; i < data.length; i++) {
            const { path, token } = data[i];
            const res = await uploadToSignedURL("posts", path, token, files[i].originFileObj);

            if (res.error) {
              message.error(res.error.message);
              resolve(null);
            } else {
              const { fullPath } = res.data;
              urlList.push(`${public_image_url}/${fullPath}`);
            }
          }

          resolve(urlList);
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const resetForm = () => {
    setValueCaption(null);
    setTypeMedia(typeMedias.image);
    setImageList([]);
    setSelectedPerfumes([]);
    setHasRelatePerfumes(false);
    setHasSound(false);
    setSelectedSound(null);
    setPlaying(true);
    setPlayingRange([0, 2]);
  };

  //handle upload image
  const [imageList, setImageList] = useState([]);

  const onImageChange = (fileList) => {
    setImageList(fileList);
  };

  //handle relate perfumes
  const [selectedPerfumes, setSelectedPerfumes] = useState([]);
  const [hasRelatePerfumes, setHasRelatePerfumes] = useState(false);

  const handleRemoveSelectPerfume = (item) => {
    const newSelectedPerfumes = selectedPerfumes.filter((perfume) => perfume.id !== item.id);
    setSelectedPerfumes(newSelectedPerfumes);
  };

  const handleSearchPerfumeAdd = (perfumes) => {
    if (selectedPerfumes && selectedPerfumes.length > 0) {
      const newSelectedPerfumes = [...selectedPerfumes];
      perfumes.forEach((item) => {
        const isExisted = selectedPerfumes.find((perfume) => perfume.id === item.id);
        if (!isExisted) {
          newSelectedPerfumes.push(item);
        }
      });

      setSelectedPerfumes(newSelectedPerfumes);
    } else {
      setSelectedPerfumes(perfumes);
    }
  };

  const handleCloseRelatePerfumes = () => {
    setSelectedPerfumes([]);
    setHasRelatePerfumes(false);
  };

  //handle sound
  const [hasSound, setHasSound] = useState(false);
  const [selectedSound, setSelectedSound] = useState(null);
  const [soundLoop, setSoundLoop] = useState(true);

  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [playingRange, setPlayingRange] = useState(selectedSound ? [0, selectedSound.duration] : [0, 10]);

  const handleCloseAddSound = () => {
    setSelectedSound(null);
    setHasSound(false);
  };

  const handleSelectSound = (sound) => {
    setSelectedSound(null);

    setTimeout(() => {
      setSelectedSound(sound);
    }, 100);
  };

  const handleTriggerPlayingSound = () => {
    if (playing) audioRef.current.pause();
    else audioRef.current.play();

    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    if (selectedSound) audioRef.current.play();
  }, [selectedSound]);

  const audioSrc = selectedSound ? stream(selectedSound.id) : null;
  const imageSrc = selectedSound ? getAvatar(selectedSound.coverArt) : null;

  const handlePlayingRangeChange = (range) => {
    const newCurrentTime = range[0];
    audioRef.current.currentTime = newCurrentTime;
    setPlayingRange(range);
  };

  const handleTimeUpdate = () => {
    const current = audioRef.current.currentTime;

    // Pause the audio when it reaches the end time
    if (current >= playingRange[1]) {
      audioRef.current.pause();
      setPlaying(false);
    }
  };

  return (
    <Flex justify="center">
      {user && (
        <div className="vfra-create-post-container">
          <Flex justify="space-between" align="center">
            <Flex justify="center" align="center" gap={12}>
              <Avatar alt="" src={user.vfra_user?.avatar ?? user.avatar_url} />
              <span style={{ fontWeight: "bold" }}>{user.vfra_user?.name ?? user.name}</span>
            </Flex>
            <span style={{ color: "#606060", fontSize: "0.8rem" }}>Visibility: Public</span>
          </Flex>

          <Radio.Group
            onChange={(e) => setTypeMedia(e.target.value)}
            value={typeMedia}
            defaultValue="image"
            style={{ margin: "16px 0" }}>
            <Radio.Button size={isMobileView ? "small" : "default"} value={typeMedias.image}>
              Image
            </Radio.Button>
            {/* <Radio.Button size={isMobileView ? "small" : "default"} value={typeMedias.video}>
              Video
            </Radio.Button> */}
          </Radio.Group>

          {typeMedia === typeMedias.image && <ImageDragger imageList={imageList} onImageChange={onImageChange} />}

          {typeMedia === typeMedias.video && <VideoDragger onImageChange={onImageChange} />}

          <TextArea
            style={{ marginTop: "30px", marginBottom: "12px" }}
            value={valueCaption}
            onChange={(e) => setValueCaption(e.target.value)}
            placeholder="Write a caption here..."
            autoSize={{
              minRows: 1,
            }}
            maxLength={300}
            variant="borderless"
          />

          {hasRelatePerfumes && (
            <Flex vertical style={{ marginBottom: "12px", position: "relative" }}>
              <Divider style={{ position: "relative", fontSize: "0.9rem" }}>
                Relate perfumers
                <Button
                  onClick={handleCloseRelatePerfumes}
                  size="small"
                  shape="circle"
                  className="vfra-close-relate-perfumes-btn"
                  icon={<CloseOutlined />}
                />
              </Divider>

              <SearchRelatePerfume onAdd={handleSearchPerfumeAdd} />

              {selectedPerfumes.length > 0 && (
                <Row
                  justify="center"
                  gutter={[12, 12]}
                  style={{ maxHeight: "300px", overflow: "scroll", paddingTop: "18px" }}>
                  {selectedPerfumes.map((item) => (
                    <Col span={isMobileView ? 24 : 8} key={item.id}>
                      <Flex
                        justify="space-between"
                        align="center"
                        className="vfra-selected-perfume-item"
                        style={{ position: "relative" }}>
                        <Flex align="center" gap={8}>
                          <img alt="" width={isMobileView ? "30px" : "40px"} src={item.image_url} />
                          <Flex vertical>
                            <span style={{ fontWeight: "700", fontSize: "0.9" }} className="one-line-ellipsis">
                              {item.name}
                            </span>
                            <span className="one-line-ellipsis" style={{ fontSize: "0.7rem" }}>
                              {item.brand_name}
                            </span>
                          </Flex>
                        </Flex>

                        <Button
                          onClick={() => handleRemoveSelectPerfume(item)}
                          size="small"
                          type="text"
                          shape="circle"
                          icon={<CloseOutlined />}
                        />
                      </Flex>
                    </Col>
                  ))}
                </Row>
              )}
            </Flex>
          )}

          {hasSound && (
            <Flex vertical style={{ marginBottom: "12px", position: "relative" }}>
              <Divider style={{ position: "relative", fontSize: "0.9rem" }}>
                Sound
                <Button
                  onClick={handleCloseAddSound}
                  size="small"
                  shape="circle"
                  className="vfra-close-relate-perfumes-btn"
                  icon={<CloseOutlined />}
                />
              </Divider>

              <SearchSound onSelect={handleSelectSound} />

              {selectedSound && (
                <Row
                  justify="center"
                  gutter={[12, 12]}
                  style={{ maxHeight: "300px", overflow: "scroll", paddingTop: "18px" }}>
                  <Col offset={0} span={isMobileView ? 24 : 12}>
                    <Flex justify="space-between" align="center" className="vfra-selected-perfume-item">
                      <Flex align="center">
                        <img
                          alt=""
                          width={isMobileView ? "35px" : "50px"}
                          className={playing ? "spinning" : ""}
                          src={imageSrc}
                          style={{ marginRight: "12px", borderRadius: "50%" }}
                        />
                        <Flex vertical>
                          <span style={{ fontWeight: "700", fontSize: "0.9" }} className="one-line-ellipsis">
                            {selectedSound.title}
                          </span>
                          <span className="one-line-ellipsis" style={{ fontSize: "0.7rem" }}>
                            {selectedSound.artist}
                          </span>
                        </Flex>
                      </Flex>

                      <Space size={12}>
                        <Button
                          onClick={handleTriggerPlayingSound}
                          shape="circle"
                          size="small"
                          icon={playing ? <PauseOutlined /> : <CaretRightOutlined />}
                        />

                        {selectedSound && (
                          <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} loop>
                            <source src={audioSrc} type="audio/mp3" />
                          </audio>
                        )}

                        <Button
                          onClick={() => setSelectedSound(null)}
                          shape="circle"
                          size="small"
                          type="text"
                          icon={<CloseOutlined />}
                        />
                      </Space>
                    </Flex>
                    <Slider
                      range
                      defaultValue={[0, selectedSound.duration]}
                      max={selectedSound.duration}
                      onChange={(e) => handlePlayingRangeChange(e)}
                    />
                    <Flex justify="center" align="center" gap={12}>
                      <span>Loop</span>
                      <Switch size="small" value={soundLoop} onChange={() => setSoundLoop((prev) => !prev)} />
                    </Flex>
                  </Col>
                </Row>
              )}
            </Flex>
          )}

          <Flex
            vertical={isMobileView}
            justify={hasRelatePerfumes && hasSound ? "end" : "space-between"}
            gap={6}
            style={{ marginTop: "12px" }}>
            <Flex justify="start" align="center" gap={12}>
              {!hasRelatePerfumes && (
                <Button
                  style={{ marginLeft: "-16px" }}
                  onClick={() => setHasRelatePerfumes(true)}
                  size={isMobileView ? "small" : "default"}
                  shape="round"
                  type="text"
                  icon={<BlockOutlined />}>
                  Relate perfumes
                </Button>
              )}

              {!hasSound && (
                <Button
                  style={{ marginLeft: hasRelatePerfumes ? "-16px" : "0" }}
                  onClick={() => setHasSound(true)}
                  size={isMobileView ? "small" : "default"}
                  shape="round"
                  type="text"
                  icon={
                    <CustomeSvgIcon icon={musicIcon} height={12} style={{ marginRight: "2px", marginBottom: "-1px" }} />
                  }>
                  Sound
                </Button>
              )}
            </Flex>

            <Flex gap={6} justify="end">
              <Button
                size={isMobileView ? "small" : "default"}
                disabled={isSubmiting}
                onClick={onClose}
                shape="round"
                type="text">
                Close
              </Button>

              {(valueCaption || imageList.length > 0 || selectedPerfumes.length > 0) && (
                <Button
                  size={isMobileView ? "small" : "default"}
                  disabled={isSubmiting}
                  onClick={onCancel}
                  shape="round"
                  type="text">
                  Cancel
                </Button>
              )}

              <Button
                size={isMobileView ? "small" : "default"}
                loading={isSubmiting}
                onClick={onSubmit}
                disabled={!valueCaption || imageList.length === 0}
                shape="round">
                Post
              </Button>
            </Flex>
          </Flex>
        </div>
      )}
    </Flex>
  );
};
export default CreatePost;
