import "./HeaderLayout.css";
import logoNotext from "../../assets/logo-notext.png";
import logoLandscape from "../../assets/logo-landscape.png";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu, Avatar, Flex, Dropdown, Button, Row, Col, Drawer, Modal } from "antd";
import { UserOutlined, PlusOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import { localStorage_fields } from "../../utils/localstorage";
import useWindowSize from "../../hooks/useWindowSize";
import CreatePost from "../../components/CreatePost/CreatePost";

const items = [
  {
    label: "Profile",
    key: "1",
  },
  {
    label: "Logout",
    key: "2",
  },
];

const HeaderLayout = () => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let currentPage = "/";
  let currentMenu = "";
  if (pathname.includes("/home")) {
    currentPage = "/home";
    currentMenu = "Explore";
  } else if (pathname.includes("/perfume")) {
    currentPage = "/perfume";
    currentMenu = "Perfume";
  } else if (pathname.includes("/brand")) {
    currentPage = "/brand";
    currentMenu = "Brand";
  } else if (pathname.includes("/profile")) {
    currentPage = "/profile";
    currentMenu = "Profile";
  } else {
    currentMenu = "";
  }

  const { user, openLoginModal, logout } = useAuth();

  const onClick = ({ key }) => {
    if (key === "1") navigate(`/profile/${user.user_id}`);
    else if (key === "2") logout();
  };

  const clearNewfeed = () => {
    localStorage.removeItem(localStorage_fields.posts);
    localStorage.removeItem(localStorage_fields.lastIndexPost);
  };

  // mobile menu
  const [open, setOpen] = useState(false);

  const showMobileMenu = () => {
    setOpen(true);
  };

  const onCloseMobileMenu = () => {
    setOpen(false);
  };

  const handleClickMobileMenu = (menu) => {
    setOpen(false);
    if (currentPage === "/home") clearNewfeed();
    navigate(menu);
  };

  const handleReloadNewFeed = () => {
    if (currentPage === "/home") {
      clearNewfeed();
      navigate("/");
    }
  };

  //handle create post
  const [showCreatePost, setShowCreatePost] = useState(false);

  const handleCreatePost = () => {
    setShowCreatePost(false);
  };

  if (isMobileView) {
    return (
      <>
        <Flex
          justify="space-between"
          align="center"
          className={`vfra-header-container ${currentPage === "/home" ? "header-mobile-home" : ""}`}>
          <Button
            className={`${currentPage === "/home" ? "header-mobile-home" : ""}`}
            onClick={showMobileMenu}
            type="text"
            shape="circle"
            icon={<MenuOutlined />}
          />

          <Flex gap={6} justify="center" align="center">
            <span onClick={handleReloadNewFeed}>{currentMenu}</span>
            {user && currentPage === "/home" && (
              <Button
                size="small"
                title="create post"
                onClick={() => setShowCreatePost(true)}
                shape="circle"
                icon={<PlusOutlined />}></Button>
            )}
          </Flex>

          {user && (
            <Flex justify="end" align="center">
              <Dropdown
                menu={{
                  items,
                  onClick,
                }}
                placement="bottomLeft"
                trigger={["click"]}>
                <Flex justify="end" align="center">
                  <Avatar src={user.vfra_user?.avatar ?? user.avatar_url} />
                </Flex>
              </Dropdown>
            </Flex>
          )}

          {!user && (
            <Flex justify="end" align="center" style={{ padding: "6px 0" }}>
              <Avatar onClick={openLoginModal} icon={<UserOutlined />} />
            </Flex>
          )}
        </Flex>

        <Modal width="100%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
          <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
        </Modal>

        <Drawer
          getContainer={document.getElementById("root")}
          title={
            <Flex align="center" justify="space-between">
              <Flex align="center">
                <Avatar onClick={() => navigate("/home")} src={<img src={logoNotext} alt="" />} />
                <b style={{ marginLeft: "6px" }}>Vfra</b>
              </Flex>

              <Button onClick={onCloseMobileMenu} type="text" shape="circle" icon={<CloseOutlined />} />
            </Flex>
          }
          placement="left"
          open={open}
          closable={false}
          onClose={onCloseMobileMenu}
          width="70%">
          <p
            className={currentPage === "/home" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/home")}>
            Explore
          </p>
          <p
            className={currentPage === "/perfume" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/perfume")}>
            Perfume
          </p>
          <p
            className={currentPage === "/brand" ? "vfra-menu-item  vfra-menu-active" : "vfra-menu-item"}
            onClick={() => handleClickMobileMenu("/brand")}>
            Brands
          </p>
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <Row className="vfra-header-container">
          <Col span={6}>
            <Flex align="center" style={{ height: "100%" }}>
              <Avatar
                style={{ width: "128px" }}
                onClick={() => navigate("/home")}
                src={<img src={logoLandscape} alt="" />}
              />
            </Flex>
          </Col>

          <Col span={12} justify="center">
            <Menu
              className="vfra-menu-style"
              theme="light"
              mode="horizontal"
              selectedKeys={currentPage}
              items={[
                {
                  key: "/home",
                  label: "Explore",
                  onClick: () => {
                    if (currentPage === "/home") clearNewfeed();
                    navigate("/");
                  },
                },
                {
                  key: "/perfume",
                  label: "Perfumes",
                  onClick: () => {
                    navigate("/perfume");
                  },
                },
                {
                  key: "/brand",
                  label: "Brands",
                  onClick: () => {
                    navigate("/brand");
                  },
                },
              ]}
            />
          </Col>

          <Col span={6} justify="end">
            {user && (
              <Flex justify="end" align="center" gap={12}>
                {currentPage === "/home" && (
                  <Button
                    title="create post"
                    onClick={() => setShowCreatePost(true)}
                    shape="round"
                    icon={<PlusOutlined />}>
                    Create
                  </Button>
                )}
                <Flex align="center">
                  <h4 className="one-line-ellipsis" style={{ marginRight: "6px" }}>
                    {user.vfra_user?.name ?? user.name}
                  </h4>
                  <Dropdown
                    menu={{
                      items,
                      onClick,
                    }}
                    placement="bottomLeft"
                    trigger={["click"]}>
                    <Flex justify="end" align="center">
                      <Avatar size="large" src={user.vfra_user?.avatar ?? user.avatar_url} />
                    </Flex>
                  </Dropdown>
                </Flex>
              </Flex>
            )}

            {!user && (
              <Flex justify="end" align="center" style={{ padding: "6px 0" }}>
                <Avatar size="large" onClick={openLoginModal} icon={<UserOutlined />} />
              </Flex>
            )}
          </Col>
        </Row>

        <Modal width="70%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
          <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
        </Modal>
      </>
    );
  }
};

export default HeaderLayout;
