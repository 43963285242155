import "./SearchRelatePerfume.css";
import { useState, useCallback, useMemo } from "react";
import { Flex, Button, Row, Col, Input, Popover, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import useWindowSize from "../../../hooks/useWindowSize";
import { search_perfumes } from "../../../services/meilisearchPerfumes";

const SearchRelatePerfume = ({ onAdd }) => {
  const { isMobileView } = useWindowSize();
  const [page, setPage] = useState(1);
  const [perfumes, setPerfumes] = useState([]);
  const [totalPerfumes, setTotalPerfumes] = useState(0);
  const [loading, setLoading] = useState(false);

  const [openPopover, setOpenPopover] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [selectedPerfumes, setSelectedPerfumes] = useState(null);

  const onSearch = useCallback(
    async (valueSearch, newPage = 1) => {
      try {
        setLoading(true);
        const searchResults = await search_perfumes(valueSearch, newPage);
        setTotalPerfumes(searchResults.estimatedTotalHits);

        if (newPage > 1) {
          setPerfumes([...perfumes, ...searchResults.hits]);
        } else {
          setPerfumes(searchResults.hits);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [perfumes]
  );

  const debouncedSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch]);

  const onLoadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    onSearch(searchValue, newPage);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (!value) {
      setPerfumes([]);
    } else {
      setPage(1);
      debouncedSearch(value);
    }

    setSearchValue(value);
  };

  const handleSelectPerfume = (item) => {
    let newSelectedPerfumes = [];
    if (selectedPerfumes && selectedPerfumes.length > 0) {
      newSelectedPerfumes = [...selectedPerfumes];

      const isExisted = selectedPerfumes.find((perfume) => perfume.id === item.id);
      if (!isExisted) {
        newSelectedPerfumes = [...newSelectedPerfumes, item];
      } else {
        newSelectedPerfumes = newSelectedPerfumes.filter((p) => p.id !== item.id);
      }
    } else {
      newSelectedPerfumes.push(item);
    }

    // mark selected
    const newPerfumes = perfumes.map((p) => {
      const isExisted = newSelectedPerfumes.find((item) => item.id === p.id);

      return {
        ...p,
        isSelected: !!isExisted,
      };
    });
    setPerfumes(newPerfumes);

    setSelectedPerfumes(newSelectedPerfumes);
  };

  const handleAdd = () => {
    onAdd(selectedPerfumes);

    setOpenPopover(false);
    setPage(1);
    setPerfumes([]);
    setTotalPerfumes(0);
    setSelectedPerfumes(null);
    setSearchValue(null);
  };

  return (
    <Popover
      getPopupContainer={false}
      open={openPopover}
      overlayStyle={{ width: "50%" }}
      title={totalPerfumes > 0 ? `Perfume search results: ${totalPerfumes}` : "Search perfume"}
      placement="bottom"
      content={
        <>
          {perfumes.length > 0 && (
            <Row
              gutter={[12, 12]}
              style={{ height: perfumes.length ? "45vh" : "0", overflow: "scroll", paddingTop: "6px" }}>
              {perfumes.map((item) => (
                <Col span={isMobileView ? 24 : 8} key={item.id}>
                  <Flex
                    justify="start"
                    align="center"
                    className={`vfra-perfume-item ${item.isSelected ? "active" : ""}`}
                    onClick={() => handleSelectPerfume(item)}>
                    <img
                      alt=""
                      width={isMobileView ? "30px" : "40px"}
                      src={item.image_url}
                      style={{ marginRight: "6px" }}
                    />
                    <Flex vertical>
                      <span style={{ fontWeight: "700", fontSize: "0.9" }} className="one-line-ellipsis">
                        {item.name}
                      </span>
                      <span className="one-line-ellipsis" style={{ fontSize: "0.7rem" }}>
                        {item.brand_name}
                      </span>
                    </Flex>

                    {item.isSelected && (
                      <div className="vfra-perfume-item-active-icon">
                        <CheckCircleOutlined />
                      </div>
                    )}
                  </Flex>
                </Col>
              ))}

              {perfumes.length < totalPerfumes && (
                <Col span={8} offset={8}>
                  <Flex justify="center" style={{ marginTop: "12px" }}>
                    <Button size="small" type="text" onClick={onLoadMore} loading={loading}>
                      Load more
                    </Button>
                  </Flex>
                </Col>
              )}
            </Row>
          )}

          {perfumes.length === 0 && (
            <Flex justify="center" align="center">
              <span style={{ fontSize: "0.8rem" }}>Your search results will be visible here</span>
            </Flex>
          )}

          <Flex align="center" gap={12} justify="end" style={{ paddingTop: "12px" }}>
            <Button onClick={() => setOpenPopover(false)} size="small" type="text">
              Close
            </Button>
            <Button loading={loading} disabled={totalPerfumes === 0} onClick={handleAdd} size="small" type="primary">
              Add {selectedPerfumes && <span>({selectedPerfumes.length})</span>}
            </Button>
          </Flex>
        </>
      }>
      <Row justify="center">
        <Col span={isMobileView ? 24 : 12}>
          <Input
            size="large"
            allowClear
            value={searchValue}
            onFocus={() => setOpenPopover(true)}
            onChange={handleSearchChange}
            placeholder="Search perfumes by name, description..."
          />
        </Col>
      </Row>
    </Popover>
  );
};

export default SearchRelatePerfume;
