import "./FeedRelatePerfumes.css";
import { Flex } from "antd";

const imageRelatePerfumeHeight = "32px";

const FeedRelatePerfumes = ({ perfumes, activePerfume, onTriggerAccords, ...props }) => {
  return (
    <Flex {...props} vertical gap={30} justify="end">
      {perfumes.map((p) => (
        <Flex
          key={p.id}
          align="center"
          className={`vfra-relate-perfume ${activePerfume && p.id === activePerfume.id ? "vfra-accords-active" : ""}`}
          onClick={() => onTriggerAccords(p)}>
          <img alt="" style={{ maxHeight: "100%" }} height={imageRelatePerfumeHeight} src={p.image_url} />
        </Flex>
      ))}
    </Flex>
  );
};

export default FeedRelatePerfumes;
