import "./PreviewPost.css";
import { Divider, Flex, Space, Carousel, Dropdown, Button, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { HeartFilled } from "@ant-design/icons";
import { useAuth } from "../../../contexts/AuthContext";
import PostImage from "./PostImage/PostImage";
const { confirm } = Modal;

const ownerItems = [
  {
    label: "Remove",
    key: "remove",
  },
];

const PreviewPost = ({ post, showManageButton = false, onRemove }) => {
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const navigate = useNavigate();

  const onMenuClick = (key) => {
    if (key === "remove") {
      showDeleteConfirm(post.id);
    }
  };

  const showDeleteConfirm = (id) => {
    return confirm({
      title: "Confirm remove post",
      content: "Are you sure you want to remove this post?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        onRemove(id);
      },
    });
  };

  return (
    <Flex gap={12} vertical className="vfra-preview-post-container" style={{ position: "relative", cursor: "pointer" }}>
      {post.media_data && post.media_data.urls && post.media_data.urls.length > 1 && (
        <Carousel slidesToShow={1} slidesToScroll={1} dotPosition="bottom" arrows infinite={false}>
          {post.media_data.urls.map((item, index) => (
            <PostImage key={index} src={item} onClick={() => navigate(`/home/${post.id}`)} />
          ))}
        </Carousel>
      )}

      {post.media_data && post.media_data.urls && post.media_data.urls.length === 1 && (
        <PostImage
          src={post.media_data ? `${post.media_data.urls[0]}` : ""}
          onClick={() => navigate(`/home/${post.id}`)}
        />
      )}

      <Flex vertical className="vfra-preview-post-info" onClick={() => navigate(`/home/${post.id}`)}>
        <Flex justify="space-between" align="center">
          <Space size="small" split={<Divider type="vertical" />} align="center">
            <Space>
              <HeartFilled />
              {post.total_hearts ?? 0}
            </Space>
          </Space>

          {isYourself && showManageButton && (
            <Dropdown
              menu={{
                items: ownerItems,
                onClick: ({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  onMenuClick(key);
                },
              }}
              placement="bottomLeft"
              trigger={["click"]}>
              <Button onClick={(e) => e.stopPropagation()} size="small" shape="circle" icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PreviewPost;
